<template>
  <div class="UserEditor">
    <div class="Headers">
      <span class="Info">First name</span>
      <span class="Info">Last name</span>
      <span class="Info Email">E-mail</span>
      <span class="Info">Company</span>
      <span class="Info">Position</span>
      <span class="Info">Status</span>
      <span class="Buttons">Actions</span>
    </div>
    <div
      class="User"
      v-for="User of Users"
      :key="User.userId"
      :style="{ height: User.openSession ? '100%' : '24px' }"
    >
      <div class="UserInfo">
        <span class="Info">{{ User.firstName }}</span>
        <span class="Info">{{ User.lastName }}</span>
        <span class="Info Email" @click="CopyEmail(User.email)">{{
          User.email
        }}</span>
        <span class="Info">{{ User.company }}</span>
        <span class="Info">{{ User.position }}</span>
        <span class="Info">{{ User.active ? "Active" : "Non-active" }}</span>
        <div class="Buttons">
          <img
            v-tooltip="'Edit user'"
            :src="require('@/assets/Admin/' + User.editIcon + '.svg')"
            @mouseenter="User.editIcon += 'Active'"
            @mouseleave="User.editIcon = User.editIcon.replace('Active', '')"
          />
          <img
            v-tooltip="'Active/lock user'"
            @click="ChangeStatusUser(User)"
            :src="require('@/assets/Admin/' + User.activeUserIcon + '.svg')"
            @mouseenter="User.activeUserIcon += 'Active'"
            @mouseleave="
              User.activeUserIcon = User.activeUserIcon.replace('Active', '')
            "
          />
          <img
            v-tooltip="'Generate password'"
            @click="RegestrationUser(User)"
            :src="require('@/assets/Admin/' + User.generatePassIcon + '.svg')"
            @mouseenter="User.generatePassIcon += 'Active'"
            @mouseleave="
              User.generatePassIcon = User.generatePassIcon.replace(
                'Active',
                ''
              )
            "
          />
          <img
            v-tooltip="'Create payment'"
            @click="CreatePayment(User)"
            :src="require('@/assets/Admin/' + User.createPayment + '.svg')"
            @mouseenter="User.createPayment += 'Active'"
            @mouseleave="
              User.createPayment = User.createPayment.replace('Active', '')
            "
          />
          <img
            :style="{ opacity: User.session.length > 0 ? '1' : '0.3' }"
            v-tooltip="'Sessions'"
            @click="User.openSession = !User.openSession"
            :src="require('@/assets/Admin/' + User.sessionIcon + '.svg')"
            @mouseenter="User.sessionIcon += 'Active'"
            @mouseleave="
              User.sessionIcon = User.sessionIcon.replace('Active', '')
            "
          />
        </div>
      </div>
      <div class="Sessions" :class="{ Hidden: !User.openSession }">
        <div
          class="Session"
          v-for="Session of User.session"
          :key="Session.sessionId"
        >
          <span class="Info">{{
            new Date(Session.connectDate).toLocaleDateString("en-US")
          }}</span>
          <span class="Info MacInfo">{{ Session.macInfo }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      Users: [],
    };
  },
  created() {
    axios
      .get(this.$api_url + "HCMWeb/GetUsers")
      .then((response) => {
        this.Users = response.data;

        this.Users.forEach((user) => {
          user.openSession = false;

          user.editIcon = "EditIcon";
          user.activeUserIcon = "StatusUserIcon";
          user.generatePassIcon = "GeneratePassIcon";
          user.createPayment = "CreatePaymentIcon";
          user.sessionIcon = "SessionIcon";
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    CopyEmail: function (Email) {
      navigator.clipboard.writeText(Email);
    },
    ChangeStatusUser: function (User) {
      axios
        .get(this.$api_url + "HCMWeb/ChangeStatusUser?UserId=" + User.userId)
        .then(() => {
          User.active = !User.active;
          this.$alert(
            "User status successfully changed.",
            "Success",
            "success"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    RegestrationUser: function (User) {
      axios
        .get(this.$api_url + "HCMWeb/Registration?UserId=" + User.userId)
        .then(() => {
          User.active = true;
          this.$alert(
            "Password successfully generated, the user will receive a message with further instructions.",
            "Success",
            "success"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    CreatePayment: function (User) {
      axios
        .get(this.$api_url + "HCMWeb/CreatePayment?UserId=" + User.userId)
        .then(() => {
          User.active = true;
          this.$alert(
            "The payment link was generated and sent to the user.",
            "Success",
            "success"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.UserEditor {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  margin-left: 24px;

  overflow: scroll;

  height: 100%;
  width: calc(100% - 200px);
}

.Headers {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  font-size: 18px;

  width: 100%;

  padding-top: 20px;
  padding-bottom: 20px;

  border-bottom: 1px solid #c4c4c4;
}

.User {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;

  padding-top: 12px;
  padding-bottom: 12px;

  border-bottom: 1px solid #c4c4c461;

  transition: 0.5s;
}

.UserInfo {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
}

.Info {
  max-width: 12.3%;
  min-width: 12.3%;

  padding-right: 16px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Email {
  max-width: 20%;
  min-width: 20%;
  cursor: pointer;
}

.Buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.Buttons img {
  width: 20px;
  height: 20px;

  padding-right: 10px;
}

.Sessions {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  padding-top: 20px;

  width: 100%;
  height: 100%;
  transition: 0.5s;

  overflow: hidden;
}

.Session {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  padding-top: 10px;
  padding-bottom: 10px;

  width: 100%;
}

.MacInfo {
  max-width: 85%;
  min-width: 85%;
}

.Hidden {
  overflow: hidden;
  height: 0px;

  transition: 0.5s;
}
</style>
