<template>
  <div class="SOPCompensation">
    <div class="ChartTitle">
      <div class="ChartTitleText">
        <span
          >Note: Sample average values consider lower approval rates in case of
          several votes on the same subject.</span
        >
      </div>
      <div class="GenerateImage">
        <span @click="GenerateImage">
          Download dashboard
          <img :src="require('@/assets/Compensation/ChartsIcon.svg')" />
        </span>
      </div>
    </div>
    <div id="DownloadChart">
      <div id="DownloadFiltersNote"></div>
      <div id="DownloadFiltersView"></div>
      <div class="Charts" id="Charts">
        <div class="ChartGroup">
          <span class="Title">Binding Say-on-Pay: Board of Directors pay</span>
          <div class="CompanyChart">
            <div v-if="FirstCompanyChart != undefined">
              <div
                class="LineChart"
                v-for="Chart of FirstCompanyChart.charts"
                :key="Chart.elementId"
              >
                <span class="Title"
                  >{{ Chart.element }} {{ Chart.compensationPlan }}</span
                >
                <div
                  class="Chart"
                  v-if="Chart.avgAbs != null && Chart.avgAbs != 0"
                  v-tooltip="FirstCompanyChart.companyName"
                >
                  <div class="Line">
                    <div
                      :style="{ width: Math.round(Chart.avgAbs * 100) + '%' }"
                      :class="{
                        Green: Chart.avgAbs >= 0.85,
                        Yellow: Chart.avgAbs >= 0.7 && Chart.avgAbs < 0.85,
                        Red: Chart.avgAbs < 0.7,
                      }"
                      class="LineValue"
                    ></div>
                  </div>
                  <span class="Percent"
                    >{{ Math.round(Chart.avgAbs * 100) }}%</span
                  >
                </div>
                <div
                  class="Chart"
                  v-else
                  v-tooltip="FirstCompanyChart.companyName"
                >
                  <span class="Approved">{{ Chart.approvalStatus }}</span>
                </div>
              </div>
            </div>
            <div v-else class="NonValue">Not available</div>
            <div
              v-if="
                FirstCompanyChart != undefined &&
                (FirstCompanyChart.charts == undefined ||
                  FirstCompanyChart.charts.length == 0)
              "
              class="NonValue"
            >
              Not available
            </div>
            <div class="Note">
              Approval rate (YES votes in % represented votes)
            </div>
          </div>
          <div class="GroupChart">
            <div
              class="Chart"
              v-if="
                FirstGroupChart.avgAbs != undefined &&
                FirstGroupChart.avgAbs != null &&
                FirstGroupChart.avgAbs != NaN
              "
            >
              <div class="Donut" v-tooltip="SelectedList">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 140 140"
                  transform="scale( 1, 1 ) rotate(133)"
                  width="160px"
                  preserveAspectRatio="xMidYMid meet"
                  class="donut"
                  role="img"
                  aria-labelledby="chartTitle-j3dgu chartDesc-j3dgu"
                >
                  <circle
                    cx="70"
                    cy="70"
                    r="50"
                    fill="none"
                    stroke="#E1E1E1"
                    stroke-width="30"
                    stroke-dasharray="240"
                  />
                  <circle
                    cx="70"
                    cy="70"
                    r="50"
                    fill="none"
                    :stroke-dashoffset="
                      314 -
                      240 * (Math.round(FirstGroupChart.avgAbs * 100) / 100)
                    "
                    stroke-dasharray="314"
                    :stroke="FirstGroupChart.color"
                    stroke-width="30"
                    style="
                      transition: all 1.2s cubic-bezier(0.57, 0.13, 0.18, 0.98)
                        0s;
                      opacity: 1;
                    "
                  />
                </svg>
              </div>
              <span class="DonutPercent"
                >{{ Math.round(FirstGroupChart.avgAbs * 100) }}%</span
              >
              <span class="DonutNote">Selected sample (avg.)</span>
            </div>
            <div v-else class="NonValue">Not available</div>
            <div class="Note">
              Approval rate (YES votes in % represented votes)
            </div>
          </div>
        </div>
        <div class="ChartGroup">
          <span class="Title">Binding Say-on-Pay: Executive Board pay</span>
          <div class="CompanyChart">
            <div v-if="SecondCompanyChart != undefined">
              <div
                class="LineChart"
                v-for="Chart of SecondCompanyChart.charts"
                :key="Chart.elementId"
              >
                <span class="Title"
                  >{{ Chart.element }} {{ Chart.compensationPlan }}</span
                >
                <div
                  class="Chart"
                  v-if="Chart.avgAbs != null && Chart.avgAbs != 0"
                  v-tooltip="SecondCompanyChart.companyName"
                >
                  <div class="Line">
                    <div
                      :style="{ width: Math.round(Chart.avgAbs * 100) + '%' }"
                      :class="{
                        Green: Chart.avgAbs >= 0.85,
                        Yellow: Chart.avgAbs >= 0.7 && Chart.avgAbs < 0.85,
                        Red: Chart.avgAbs < 0.7,
                      }"
                      class="LineValue"
                    ></div>
                  </div>
                  <span class="Percent"
                    >{{ Math.round(Chart.avgAbs * 100) }}%</span
                  >
                </div>
                <div
                  class="Chart"
                  v-else
                  v-tooltip="SecondCompanyChart.companyName"
                >
                  <span class="Approved">{{ Chart.approvalStatus }}</span>
                </div>
              </div>
            </div>
            <div v-else class="NonValue">Not available</div>
            <div
              v-if="
                SecondCompanyChart != undefined &&
                (SecondCompanyChart.charts == undefined ||
                  SecondCompanyChart.charts.length == 0)
              "
              class="NonValue"
            >
              Not available
            </div>
            <div class="Note">
              Approval rate (YES votes in % represented votes)
            </div>
          </div>
          <div class="GroupChart">
            <div
              class="Chart"
              v-if="
                SecondGroupChart.avgAbs != undefined &&
                SecondGroupChart.avgAbs != null &&
                SecondGroupChart.avgAbs != NaN
              "
            >
              <div class="Donut" v-tooltip="SelectedList">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 140 140"
                  transform="scale( 1, 1 ) rotate(133)"
                  width="160px"
                  preserveAspectRatio="xMidYMid meet"
                  class="donut"
                  role="img"
                  aria-labelledby="chartTitle-j3dgu chartDesc-j3dgu"
                >
                  <circle
                    cx="70"
                    cy="70"
                    r="50"
                    fill="none"
                    stroke="#E1E1E1"
                    stroke-width="30"
                    stroke-dasharray="240"
                  />
                  <circle
                    cx="70"
                    cy="70"
                    r="50"
                    fill="none"
                    :stroke-dashoffset="
                      314 -
                      240 * (Math.round(SecondGroupChart.avgAbs * 100) / 100)
                    "
                    stroke-dasharray="314"
                    :stroke="SecondGroupChart.color"
                    stroke-width="30"
                    style="
                      transition: all 1.2s cubic-bezier(0.57, 0.13, 0.18, 0.98)
                        0s;
                      opacity: 1;
                    "
                  />
                </svg>
              </div>
              <span class="DonutPercent"
                >{{ Math.round(SecondGroupChart.avgAbs * 100) }}%</span
              >
              <span class="DonutNote">Selected sample (avg.)</span>
            </div>
            <div v-else class="NonValue">Not available</div>
            <div class="Note">
              Approval rate (YES votes in % represented votes)
            </div>
          </div>
        </div>
        <div class="ChartGroup">
          <span class="Title">Advisory Say-on-Pay: compensation report</span>
          <div class="CompanyChart">
            <div v-if="ThirdCompanyChart != undefined">
              <div
                class="LineChart"
                v-for="Chart of ThirdCompanyChart.charts"
                :key="Chart.elementId"
              >
                <span class="Title">Compensation report</span>
                <div
                  class="Chart"
                  v-if="Chart.avgAbs != null && Chart.avgAbs != 0"
                  v-tooltip="ThirdCompanyChart.companyName"
                >
                  <div class="Line">
                    <div
                      :style="{ width: Math.round(Chart.avgAbs * 100) + '%' }"
                      :class="{
                        Green: Chart.avgAbs >= 0.85,
                        Yellow: Chart.avgAbs >= 0.7 && Chart.avgAbs < 0.85,
                        Red: Chart.avgAbs < 0.7,
                      }"
                      class="LineValue"
                    ></div>
                  </div>
                  <span class="Percent"
                    >{{ Math.round(Chart.avgAbs * 100) }}%</span
                  >
                </div>
                <div
                  class="Chart"
                  v-else
                  v-tooltip="ThirdCompanyChart.companyName"
                >
                  <span class="Approved">{{ Chart.approvalStatus }}</span>
                </div>
              </div>
            </div>
            <div v-else class="NonValue">Not available</div>
            <div
              v-if="
                ThirdCompanyChart != undefined &&
                (ThirdCompanyChart.charts == undefined ||
                  ThirdCompanyChart.charts.length == 0)
              "
              class="NonValue"
            >
              Not available
            </div>
            <div class="Note">
              Approval rate (YES votes in % represented votes)
            </div>
          </div>
          <div class="GroupChart">
            <div
              class="Chart"
              v-if="
                ThirdGroupChart.avgAbs != undefined &&
                ThirdGroupChart.avgAbs != null &&
                ThirdGroupChart.avgAbs != NaN
              "
            >
              <div class="Donut" v-tooltip="SelectedList">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 140 140"
                  transform="scale( 1, 1 ) rotate(133)"
                  width="160px"
                  preserveAspectRatio="xMidYMid meet"
                  class="donut"
                  role="img"
                  aria-labelledby="chartTitle-j3dgu chartDesc-j3dgu"
                >
                  <circle
                    cx="70"
                    cy="70"
                    r="50"
                    fill="none"
                    stroke="#E1E1E1"
                    stroke-width="30"
                    stroke-dasharray="240"
                  />
                  <circle
                    cx="70"
                    cy="70"
                    r="50"
                    fill="none"
                    :stroke-dashoffset="
                      314 -
                      240 * (Math.round(ThirdGroupChart.avgAbs * 100) / 100)
                    "
                    stroke-dasharray="314"
                    :stroke="ThirdGroupChart.color"
                    stroke-width="30"
                    style="
                      transition: all 1.2s cubic-bezier(0.57, 0.13, 0.18, 0.98)
                        0s;
                      opacity: 1;
                    "
                  />
                </svg>
              </div>
              <span class="DonutPercent"
                >{{ Math.round(ThirdGroupChart.avgAbs * 100) }}%</span
              >
              <span class="DonutNote">Selected sample (avg.)</span>
            </div>
            <div v-else class="NonValue">Not available</div>
            <div class="Note">
              Approval rate (YES votes in % represented votes)
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as htmlToImage from "html-to-image";
// eslint-disable-next-line
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

export default {
  props: {
    AGMYear: Number,
    SelectMainCompany: Object,
    SelectedCompanyList: Array,
  },
  data() {
    return {
      SOP: [],
      FirstCompanyChart: {},
      SecondCompanyChart: {},
      ThirdCompanyChart: {},

      FirstGroupChart: {},
      SecondGroupChart: {},
      ThirdGroupChart: {},

      SelectedList: "",
    };
  },
  created() {
    this.SOP = JSON.parse(localStorage.getItem("sop") || "[]");

    if (
      this.SOP != undefined &&
      this.SOP.chartData1 != undefined &&
      this.SOP.chartData1.length > 0
    ) {
      this.UpdateFirtsChart();
      this.UpdateSecondsChart();
      this.UpdateThirdsChart();
    }
  },
  methods: {
    UpdateFirtsChart: function () {
      this.FirstCompanyChart = {};

      if (
        this.SelectMainCompany != undefined &&
        this.SelectMainCompany != null
      ) {
        this.FirstCompanyChart = this.SOP.chartData1.find(
          (x) =>
            x.companyName == this.SelectMainCompany.name &&
            x.year == this.AGMYear
        );
      }

      var companies = this.SOP.chartData1.filter(
        (x) =>
          this.SelectedCompanyList.find((y) => y.name == x.companyName) !=
            undefined && x.year == this.AGMYear
      );

      this.FirstGroupChart = {};

      if (companies != undefined && companies != null) {
        var sum = 0;
        var number = 0;

        companies.forEach((company) => {
          company.charts.forEach((chart) => {
            if (chart.avgAbs != null && chart.avgAbs != 0) {
              sum += chart.avgAbs;
              number++;
            }
          });
        });

        if (sum != null && number != null && sum != 0 && number != 0) {
          this.FirstGroupChart.avgAbs = sum / number;

          if (this.FirstGroupChart.avgAbs >= 0.85)
            this.FirstGroupChart.color = "#22AD9D";
          else if (
            this.FirstGroupChart.avgAbs >= 0.7 &&
            this.FirstGroupChart.avgAbs < 0.85
          )
            this.FirstGroupChart.color = "#EABE47";
          else if (this.FirstGroupChart.avgAbs < 0.7)
            this.FirstGroupChart.color = "#EC5050";
        }

        this.SelectedList = "";

        var Selected = JSON.parse(
          localStorage.getItem("selectedFilters") || "[]"
        );

        Selected.forEach((element) => {
          this.SelectedList += element.name + ", ";
        });

        if (this.SelectedList != "") {
          this.SelectedList = this.SelectedList.substring(
            0,
            this.SelectedList.length - 2
          );
        }
      }
    },
    UpdateSecondsChart: function () {
      this.SecondCompanyChart = {};

      if (
        this.SelectMainCompany != undefined &&
        this.SelectMainCompany != null
      ) {
        this.SecondCompanyChart = this.SOP.chartData2.find(
          (x) =>
            x.companyName == this.SelectMainCompany.name &&
            x.year == this.AGMYear
        );
      }

      var companies = this.SOP.chartData2.filter(
        (x) =>
          this.SelectedCompanyList.find((y) => y.name == x.companyName) !=
            undefined && x.year == this.AGMYear
      );

      this.SecondGroupChart = {};

      if (companies != undefined && companies != null) {
        var sum = 0;
        var number = 0;

        companies.forEach((company) => {
          company.charts.forEach((chart) => {
            if (chart.avgAbs != null && chart.avgAbs != 0) {
              sum += chart.avgAbs;
              number++;
            }
          });
        });

        if (sum != null && number != null && sum != 0 && number != 0) {
          this.SecondGroupChart.avgAbs = sum / number;

          if (this.SecondGroupChart.avgAbs >= 0.85)
            this.SecondGroupChart.color = "#22AD9D";
          else if (
            this.SecondGroupChart.avgAbs >= 0.7 &&
            this.SecondGroupChart.avgAbs < 0.85
          )
            this.SecondGroupChart.color = "#EABE47";
          else if (this.SecondGroupChart.avgAbs < 0.7)
            this.SecondGroupChart.color = "#EC5050";
        }

        this.SelectedList = "";

        var Selected = JSON.parse(
          localStorage.getItem("selectedFilters") || "[]"
        );

        Selected.forEach((element) => {
          this.SelectedList += element.name + ", ";
        });

        if (this.SelectedList != "") {
          this.SelectedList = this.SelectedList.substring(
            0,
            this.SelectedList.length - 2
          );
        }
      }
    },
    UpdateThirdsChart: function () {
      this.ThirdCompanyChart = {};

      if (
        this.SelectMainCompany != undefined &&
        this.SelectMainCompany != null
      ) {
        this.ThirdCompanyChart = this.SOP.chartData3.find(
          (x) =>
            x.companyName == this.SelectMainCompany.name &&
            x.year == this.AGMYear
        );
      }

      var companies = this.SOP.chartData3.filter(
        (x) =>
          this.SelectedCompanyList.find((y) => y.name == x.companyName) !=
            undefined && x.year == this.AGMYear
      );

      this.ThirdGroupChart = {};

      if (companies != undefined && companies != null) {
        var sum = 0;
        var number = 0;

        companies.forEach((company) => {
          company.charts.forEach((chart) => {
            if (chart.avgAbs != null && chart.avgAbs != 0) {
              sum += chart.avgAbs;
              number++;
            }
          });
        });

        if (sum != null && number != null && sum != 0 && number != 0) {
          this.ThirdGroupChart.avgAbs = sum / number;

          if (this.ThirdGroupChart.avgAbs >= 0.85)
            this.ThirdGroupChart.color = "#22AD9D";
          else if (
            this.ThirdGroupChart.avgAbs >= 0.7 &&
            this.ThirdGroupChart.avgAbs < 0.85
          )
            this.ThirdGroupChart.color = "#EABE47";
          else if (this.ThirdGroupChart.avgAbs < 0.7)
            this.ThirdGroupChart.color = "#EC5050";
        }

        this.SelectedList = "";

        var Selected = JSON.parse(
          localStorage.getItem("selectedFilters") || "[]"
        );

        Selected.forEach((element) => {
          this.SelectedList += element.name + ", ";
        });

        if (this.SelectedList != "") {
          this.SelectedList = this.SelectedList.substring(
            0,
            this.SelectedList.length - 2
          );
        }
      }
    },
    GenerateImage: function () {
      var node = document.getElementById("DownloadChart");

      const SelectedFilters = document.getElementById("SelectedFilters");
      const clone = SelectedFilters.cloneNode(true);

      clone.style.margin = "0";
      clone.style.paddingTop = "25px";
      clone.style.backgroundColor = "#ffffff";
      clone.style.width = "1164px";

      var ClearAll = clone.getElementsByClassName("ClearAll");

      var Prop = ClearAll[0].cloneNode(true);
      var Note = ClearAll[0].cloneNode(true);

      ClearAll[0].remove();

      Prop.innerText = "AGM Season - " + this.AGMYear;
      Note.innerText =
        "Note: Sample average values consider lower approval rates in case of several votes on the same subject.";

      Prop.style.display = "inline-block";
      Prop.style.paddingTop = "12px";
      Prop.style.paddingBottom = "12px";
      Prop.style.paddingLeft = "12px";
      Prop.style.width = "188px";
      Prop.style.color = "#bca15a";
      Prop.style.backgroundColor = "#f4f4f4";

      Note.style.display = "inline-block";
      Note.style.paddingTop = "12px";
      Note.style.paddingBottom = "12px";
      Note.style.paddingLeft = "15px";
      Note.style.width = "964px";
      Note.style.color = "#8a8a8ab3";
      Note.style.backgroundColor = "#f4f4f4";

      var Cross = clone.getElementsByClassName("FilterCross");

      for (let index = 0; index < Cross.length; index++) {
        const element = Cross[index];
        element.style.width = "0px";
        element.style.margin = "0";
      }

      document.getElementById("DownloadFiltersNote").appendChild(Prop);
      document.getElementById("DownloadFiltersNote").appendChild(Note);
      document.getElementById("DownloadFiltersView").appendChild(clone);

      node.style.paddingTop = "34px";
      node.style.paddingLeft = "24px";
      node.style.width = "1212px";
      node.style.backgroundColor = "#ffffff";

      var FileName = "Say-on-Pay_results";

      if (
        this.SelectMainCompany != undefined &&
        this.SelectMainCompany.name != undefined
      ) {
        FileName += "__" + this.SelectMainCompany.name;
      }

      FileName += "__AGM-" + this.AGMYear.toString() + ".jpeg";

      htmlToImage.toJpeg(node, { quality: 1 }).then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = FileName;
        link.href = dataUrl;
        link.click();
      });

      setTimeout(() => {
        node.style = null;
        clone.remove();
        Prop.remove();
        Note.remove();
      }, 50);
    },
  },
  watch: {
    SelectMainCompany: {
      handler: function () {
        this.SOP = JSON.parse(localStorage.getItem("sop") || "[]");
        if (
          this.SOP != undefined &&
          this.SOP.chartData1 != undefined &&
          this.SOP.chartData1.length > 0
        ) {
          this.UpdateFirtsChart();
          this.UpdateSecondsChart();
          this.UpdateThirdsChart();
        }
      },
      deep: true,
    },
    SelectedCompanyList: {
      handler: function () {
        this.SOP = JSON.parse(localStorage.getItem("sop") || "[]");
        if (
          this.SOP != undefined &&
          this.SOP.chartData1 != undefined &&
          this.SOP.chartData1.length > 0
        ) {
          this.UpdateFirtsChart();
          this.UpdateSecondsChart();
          this.UpdateThirdsChart();
        }
      },
      deep: true,
    },
    AGMYear: function () {
      this.SOP = JSON.parse(localStorage.getItem("sop") || "[]");
      if (
        this.SOP != undefined &&
        this.SOP.chartData1 != undefined &&
        this.SOP.chartData1.length > 0
      ) {
        this.UpdateFirtsChart();
        this.UpdateSecondsChart();
        this.UpdateThirdsChart();
      }
    },
  },
};
</script>

<style scoped>
.SOPCompensation {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;

  margin-top: 25px;
  margin-left: 24px;
}

.ChartTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 12px;
  font-weight: 500;
  color: #8a8a8ab3;

  padding-left: 12px;

  height: 35px;

  background-color: #f4f4f4;

  max-width: 1152px;
  width: 100%;
}

.ChartTitleText {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.GenerateImage {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.GenerateImage span {
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-size: 14px;
  font-weight: 500;

  width: 220px;
  height: 34px;

  color: #ffffff;

  background-color: #bca15a;

  cursor: pointer;

  transition: 0.3s;
}

.GenerateImage span:hover {
  opacity: 0.7;

  transition: 0.3s;
}

.GenerateImage img {
  height: 24px;

  margin-left: 10px;
}

.Charts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: #ffffff;

  padding-top: 30px;
}

.ChartGroup {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  margin-right: 24px;
  margin-bottom: 34px;
}

.ChartGroup:last-child {
  margin-right: 0px;
}

.ChartGroup .Title {
  font-size: 14px;
  font-weight: 600;
  color: #333333;

  margin-bottom: 11px;
}

.CompanyChart {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;

  position: relative;

  width: 340px;
  height: 300px;

  box-shadow: 4px 4px 20px rgb(0 0 0 / 9%);
  padding: 16px;

  margin-bottom: 24px;
}

.GroupChart {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;

  position: relative;

  width: 340px;
  height: 300px;

  box-shadow: 4px 4px 20px rgb(0 0 0 / 9%);
  padding: 16px;
}

.Chart {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  position: relative;

  width: 340px;
  height: 100%;
}

.Chart .Approved {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: rgba(138, 138, 138, 0.7);
}

.LineChart {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 20px;
}

.LineChart .Title {
  font-size: 14px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(138, 138, 138, 0.7);
}

.LineChart .Line {
  position: relative;

  width: 280px;
  height: 25px;
}

.LineChart .Chart {
  transition: 0.2s;
}

.LineChart .Chart:hover {
  transform: scale(1.05);

  transition: 0.2s;
}

.LineChart .LineValue {
  width: 0%;
  height: 100%;

  transition: 0.5s;
}

.LineChart .Percent {
  font-size: 14px;
  font-weight: 500;
  color: rgba(138, 138, 138, 0.7);
}

.Green {
  background: #22ad9d;
}

.Yellow {
  background: #eabe47;
}

.Red {
  background: #ec5050;
}

.Donut {
  position: absolute;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -55%);
}

.Donut svg {
  transition: 0.2s;
}

.Donut svg:hover {
  transform: scale(1.05) rotate(133deg);

  transition: 0.2s;
}

.DonutPercent {
  position: absolute;

  left: 50%;
  top: 50%;
  transform: translate(-50%, 25%);

  font-size: 14px;
  font-weight: 500;
  color: rgba(138, 138, 138, 0.7);
}

.DonutNote {
  position: absolute;

  left: 50%;
  top: 70%;
  transform: translate(-50%, 25%);

  font-size: 15px;
  font-weight: 500;
  line-height: 13px;
  color: rgba(138, 138, 138, 0.7);
}

.CompanyChart .Note {
  position: absolute;

  bottom: 16px;
  left: 16px;
  right: 16px;

  box-shadow: 4px 4px 20px rgb(0 0 0 / 9%);
  padding: 10px;

  font-size: 12px;
  font-weight: 500;
  color: #8a8a8ab3;
}

.GroupChart .Note {
  position: absolute;

  bottom: 16px;
  left: 16px;
  right: 16px;

  box-shadow: 4px 4px 20px rgb(0 0 0 / 9%);
  padding: 10px;

  font-size: 12px;
  font-weight: 500;
  color: #8a8a8ab3;
}

.NonValue {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 85%;

  font-size: 16px;
  font-weight: 500;
  color: rgba(138, 138, 138, 0.7);
}
</style>
