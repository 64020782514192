<template>
    <div class="ChartGroup">
        <span class="Title">Types of Board fees</span>
        <div class="CompanyChart">
            <div v-if="FeesCompanyChart != undefined">
                <div class="LineChart" v-for="Position of FeesCompanyChart.positions" :key="Position.id">
                    <span class="Title">{{Position.position}}</span>
                    <div class="Chart">
                        <div class="Line">
                            <div v-tooltip="FeesCompanyChart.companyName" :class="{ Active: Position.pays_Base }" class="LineValue">
                              <span class="Value">Base fee</span>
                            </div>  
                            <div v-tooltip="FeesCompanyChart.companyName" :class="{ Active: Position.pays_Committee }" class="LineValue">
                              <span class="Value">Committee fee</span>
                            </div>   
                            <div v-tooltip="FeesCompanyChart.companyName" :class="{ Active: Position.pays_Attendance }" class="LineValue">
                              <span class="Value">Attendance fee</span>
                            </div>       
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="NonValue">Not available</div>
            <div v-if="FeesCompanyChart != undefined && (FeesCompanyChart.positions == undefined || FeesCompanyChart.positions.length == 0)" class="NonValue">Not available</div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        FeesCompanyChart: Object
    }

}
</script>

<style scoped>

.ChartGroup{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 34px;
}

.ChartGroup .Title{
  font-size: 14px;
  font-weight: 600;
  color: #333333;

  margin-bottom: 11px;
}

.ChartGroup .CompanyChart{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;

  position: relative;

  width: 340px;
  height: 300px;

  box-shadow: 4px 4px 20px rgb(0 0 0 / 9%);

  padding: 16px;
  padding-top: 30px;

  margin-bottom: 24px;
}

.Chart{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  position: relative;

  width: 340px;
  height: 100%;
}

.Chart .NullValue{
  width: 100%;

  text-align: center;

  font-size: 14px;
  line-height: 25px;
  font-weight: 500;
  color: #7d7979;

  background-color: #F4F4F4;
}

.LineChart{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 40px;
}

.LineChart .Title{
  font-size: 14px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(138, 138, 138, 0.7);
}

.LineChart .Line{
  display: flex;
  align-items: center;

  position: relative;

  width: 100%;
  height: 25px;
}

.LineChart .LineValue{
  display: flex;
  align-items: center;
  justify-content: center;
    
  width: 33.3%;
  height: 100%;

  background: #F4F4F4;

  border-right: 1px solid #FFFFFF;

  transition: 0.5s;    
}

.LineChart .LineValue{
  transition: 0.2s;
}

.LineChart .LineValue:hover{
  transform: scale(1.1);

  border: none;
  z-index: 2;

  transition: 0.2s;
}

.Active{    
  background: #C0C3D2 !important;
}

.Active .Value{    
  color: #FFFFFF !important;
}

.LineChart .Value{
  font-size: 12px;
  font-weight: 500;
  color: #7d7979;
}

.NonValue{
  display: flex;
  align-items: center;
  justify-content: center;

  height: 85%;

  font-size: 16px;
  font-weight: 500;
  color: rgba(138, 138, 138, 0.7);
}

</style>