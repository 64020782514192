<template>
    <div class="ChartGroup">
      <span class="Title">Types of Board fees</span>
      <div class="FeesCompanyChart">
        <div>
          <div class="LineChart">
              <span class="Title">Selected sample (avg.)</span>
              <div class="Chart" v-if="(SampleChart.type_A != null && SampleChart.type_A != 0) || (SampleChart.type_B != null && SampleChart.type_B != 0) || (SampleChart.type_C != null && SampleChart.type_C != 0) || (SampleChart.type_D != null && SampleChart.type_D != 0)">
                  <div class="Line">
                    <div v-tooltip="{content: GetTypeProcent(SampleChart.type_A) > 6 ? 'Type A' : 'Type A: ' + GetTypeProcent(SampleChart.type_A) + '%'}" v-if="SampleChart.type_A != null && SampleChart.type_A != 0" :style="{ 'width': GetTypeProcent(SampleChart.type_A) + '%' }" class="LineValue Grey">
                      <span v-if="GetTypeProcent(SampleChart.type_A) > 6" class="Value">{{GetTypeProcent(SampleChart.type_A)}}%</span>
                    </div>   
                    <div v-tooltip="{content: GetTypeProcent(SampleChart.type_B) > 6 ? 'Type B' : 'Type B: ' + GetTypeProcent(SampleChart.type_B) + '%'}" v-if="SampleChart.type_B != null && SampleChart.type_B != 0" :style="{ 'width': GetTypeProcent(SampleChart.type_B) + '%' }" class="LineValue Red">
                      <span v-if="GetTypeProcent(SampleChart.type_B) > 6" class="Value">{{GetTypeProcent(SampleChart.type_B)}}%</span>
                    </div>   
                    <div v-tooltip="{content: GetTypeProcent(SampleChart.type_C) > 6 ? 'Type C' : 'Type C: ' + GetTypeProcent(SampleChart.type_C) + '%'}" v-if="SampleChart.type_C != null && SampleChart.type_C != 0" :style="{ 'width': GetTypeProcent(SampleChart.type_C) + '%' }" class="LineValue Purple">
                      <span v-if="GetTypeProcent(SampleChart.type_C) > 6" class="Value">{{GetTypeProcent(SampleChart.type_C)}}%</span>
                    </div>   
                    <div v-tooltip="{content: GetTypeProcent(SampleChart.type_D) > 6 ? 'Type D' : 'Type D: ' + GetTypeProcent(SampleChart.type_D) + '%'}" v-if="SampleChart.type_D != null && SampleChart.type_D != 0" :style="{ 'width': GetTypeProcent(SampleChart.type_D) + '%' }" class="LineValue Blue">
                      <span v-if="GetTypeProcent(SampleChart.type_D) > 6" class="Value">{{GetTypeProcent(SampleChart.type_D)}}%</span>
                    </div>          
                  </div>
              </div>
              <div class="Chart" v-else>
                  <span class="NullValue">not comparable</span>
              </div>
          </div>
          <div class="LineChart" v-for="Position of FeesCompanyChart.positions" :key="Position.id">
              <span class="Title">{{FeesCompanyChart.companyName}}</span>
              <div class="Chart">
                  <div class="Line">
                            <div v-tooltip="FeesCompanyChart.companyName" :class="{ Active: Position.pays_Base }" class="LineValue Disactive">
                              <span :style="{ color: Position.pays_Base ? '#FFFFFF' : '#7d7979' }" style="font-size: 12px;" class="Value">Base fee</span>
                            </div>  
                            <div v-tooltip="FeesCompanyChart.companyName" :class="{ Active: Position.pays_Committee }" class="LineValue Disactive">
                              <span :style="{ color: Position.pays_Committee ? '#FFFFFF' : '#7d7979' }" style="font-size: 12px;" class="Value">Committee fee</span>
                            </div>   
                            <div v-tooltip="FeesCompanyChart.companyName" :class="{ Active: Position.pays_Attendance }" class="LineValue Disactive">
                              <span :style="{ color: Position.pays_Attendance ? '#FFFFFF' : '#7d7979' }" style="font-size: 12px;" class="Value">Attendance fee</span>
                            </div>       
                        </div>
              </div>
          </div>
          <div class="Legends"> 
            <img :src="require('@/assets/Compensation/BODFeesLegend.svg')"/>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props:{
      FeesCompanyChart: Object,
      FeesGroupChart: Array
  },
  data(){
    return{
      SampleChart: {}
    }
  },
  mounted(){
    this.UpdateChart();
  },
  methods:{
    UpdateChart: function(){
      var tempA = 0;
      var tempB = 0;
      var tempC = 0;
      var tempD = 0;

      var number = 0;
  
      this.FeesGroupChart.forEach(chart => {
        chart.positions.forEach(position => {
          if(position.type == "A")
            tempA += 1;
          if(position.type == "B")
            tempB += 1;
          if(position.type == "C")
            tempC += 1;
          if(position.type == "D")
            tempD += 1;

          number++;
        });
      });

      this.SampleChart = {
        type_A: tempA / number,
        type_B: tempB / number,
        type_C: tempC / number,
        type_D: tempD / number,
      }
    },
    GetTypeProcent: function(ValueType){
      return Math.round((100/ (this.SampleChart.type_A + this.SampleChart.type_B + this.SampleChart.type_C + this.SampleChart.type_D)) * ValueType)
    }
  },
  watch:{
    "FeesCompanyChart": { 
      handler: function () {
        this.UpdateChart();
      },
      deep: true
    }, 
    "FeesGroupChart": { 
      handler: function () {
        this.UpdateChart();
      },
      deep: true
    }
  }

}
</script>

<style scoped>

.ChartGroup{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 34px;
}

.ChartGroup .Title{
  font-size: 14px;
  font-weight: 600;
  color: #333333;

  margin-bottom: 11px;
}

.ChartGroup .FeesCompanyChart{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;

  position: relative;

  width: 340px;
  height: 300px;

  box-shadow: 4px 4px 20px rgb(0 0 0 / 9%);

  padding: 16px;
  padding-top: 30px;

  margin-bottom: 24px;
}

.Chart{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  position: relative;

  width: 340px;
  height: 100%;
}

.Chart .NullValue{
  width: 100%;

  text-align: center;

  font-size: 14px;
  line-height: 25px;
  font-weight: 500;
  color: #7d7979;

  background-color: #F4F4F4;
}

.LineChart{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 40px;
}

.LineChart .Title{
  font-size: 14px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(138, 138, 138, 0.7);
}

.LineChart .Line{
  display: flex;
  align-items: center;

  position: relative;

  width: 100%;
  height: 25px;
}

.LineChart .LineValue{
  display: flex;
  align-items: center;
  justify-content: center;
    
  width: 0%;
  height: 100%;

  border-right: 1px solid #FFFFFF;

  transition: 0.5s;    
}

.LineChart .LineValue{
  transition: 0.2s;
}

.LineChart .LineValue:hover{
  transform: scale(1.1);

  border: none;
  z-index: 2;

  transition: 0.2s;
}

.Blue{
  background: #789ECF;
}

.Purple{
  background: #405068;
}

.Red{
  background: #EC9750;
}

.Grey{
  background: #C0C3D2;
}

.Disactive{
  width: 33.3% !important;

  background: #F4F4F4;
}

.Active{    
  background: #C0C3D2 !important;
}

.LineChart .Value{
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
}

.NonValue{
  display: flex;
  align-items: center;
  justify-content: center;

  height: 85%;

  font-size: 16px;
  font-weight: 500;
  color: rgba(138, 138, 138, 0.7);
}

.Legends{    
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  position: absolute;

  width: 340px;

  bottom: 16px;
}

.Legends img{    
  width: 340px;
}

</style>