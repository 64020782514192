<template>
  <div class="SelectedFilters" id="SelectedFilters">
    <div class="CompanyBlock">
      <span>Selected</span>
      <div
        class="SelectItem"
        v-if="SelectMainCompany != null"
        v-tooltip="SelectMainCompany.name"
      >
        <span>{{ SelectMainCompany.name }}</span>
        <span @click="$emit('RemoveMainCompany')" class="FilterCross">×</span>
      </div>
    </div>
    <div class="GoupBlock">
      <div class="GoupSelectedBlock">
        <div
          class="SelectItem"
          v-for="SelectedFilter of SelectedFilters"
          :key="SelectedFilter.id"
          v-tooltip="SelectedFilter.name"
        >
          <span>{{ SelectedFilter.name }}</span>
          <span
            @click="$emit('RemoveFilterItem', SelectedFilter)"
            class="FilterCross"
            >×</span
          >
        </div>
      </div>
      <span @click="$emit('ClearAll')" class="ClearAll">Clear all</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    SelectMainCompany: Object,
    SelectedFilters: Array,
  },
};
</script>

<style scoped>
.SelectedFilters {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;

  min-height: 35px;

  margin: 0px 25px;

  padding-top: 15px;

  border-bottom: 1px solid #c4c4c4;
}

.SelectedFilters .SelectItem {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  padding: 0px 8px;

  background-color: rgba(188, 160, 90, 0.25);
}

.SelectedFilters .SelectItem:hover {
  transform: scale(1.2);
}

.SelectedFilters .SelectItem span {
  max-width: 60px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 12px;
  font-weight: 500;

  margin: 0;

  color: #8a8a8ab3;
}

.SelectedFilters .SelectItem .FilterCross {
  font-size: 20px;
  font-weight: 500;

  margin: 0;
  margin-left: 8px;

  cursor: pointer;
}

.SelectedFilters .SelectItem .FilterCross:hover {
  transform: scale(1.4);
}

.CompanyBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  width: 200px;
  min-width: 200px;
  max-width: 200px;

  border-right: 1.5px solid rgba(196, 196, 196, 0.5);
}

.CompanyBlock > span {
  height: 22px;
  font-size: 18px;
  font-weight: 500;
  color: #8a8a8ab3;
  margin-right: 20px;
}

.GoupBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;

  margin-left: 15px;
}

.GoupSelectedBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.GoupSelectedBlock .SelectItem {
  margin-right: 15px;
  margin-bottom: 15px;
}

.GoupBlock .ClearAll {
  margin: 0;

  padding: 0px 8px;

  white-space: nowrap;

  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #bca15a;
  cursor: pointer;
}

.GoupBlock .ClearAll:hover {
  background: #dfe2e4;
}
</style>
