<template>
    <div class="ChartGroup">
        <span class="Title">Total direct compensation (TDC), CHFk</span>
        <div class="CompanyChart">
            <div v-if="CompanyChart != undefined">
                <div class="LineChart" v-for="Position of CompanyChart.positions" :key="Position.id">
                    <span class="Title">{{Position.position}}</span>
                    <div class="Chart" v-if="Position.tdc != null && Position.tdc != 0" v-tooltip="CompanyChart.companyName">
                        <div class="Line">
                            <div :style="{ 'width': (100 / Math.max(...CompanyChart.positions.map(o => o.tdc))) * Position.tdc + '%' }" class="LineValue"></div>
                            <span class="Value">{{Math.round(Position.tdc / 1000).toLocaleString('ru-RU')}}</span>
                        </div>
                    </div>
                    <div class="Chart" v-else v-tooltip="CompanyChart.companyName">
                        <span class="NullValue">not comparable</span>
                    </div>
                </div>
            </div>
            <div v-else class="NonValue">Not available</div>
            <div v-if="CompanyChart != undefined && (CompanyChart.positions == undefined || CompanyChart.positions.length == 0)" class="NonValue">Not available</div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        CompanyChart: Object
    }
}
</script>

<style scoped>

.ChartGroup{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  margin-right: 24px;
  margin-bottom: 34px;
}

.ChartGroup .Title{
  font-size: 14px;
  font-weight: 600;
  color: #333333;

  margin-bottom: 11px;
}

.ChartGroup .CompanyChart{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;

  position: relative;

  width: 340px;
  height: 300px;

  box-shadow: 4px 4px 20px rgb(0 0 0 / 9%);

  padding: 16px;
  padding-top: 30px;

  margin-bottom: 24px;
}

.Chart{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  position: relative;

  width: 340px;
  height: 100%;
}

.Chart .NullValue{
  width: 100%;

  text-align: center;

  font-size: 14px;
  line-height: 25px;
  font-weight: 500;
  color: #7d7979;

  background-color: #F4F4F4;
}

.LineChart{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 40px;
}

.LineChart .Title{
  font-size: 14px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(138, 138, 138, 0.7);
}

.LineChart .Line{
  display: flex;
  align-items: center;

  position: relative;

  width: 100%;
  height: 25px;
}

.LineChart .Chart{
  transition: 0.2s;
}

.LineChart .Chart:hover{
  transform: scale(1.05);

  transition: 0.2s;
}

.LineChart .LineValue{
  width: 0%;
  height: 100%;

  transition: 0.5s;    
  
  background: #C0C3D2;
}

.LineChart .Value{
  font-size: 14px;
  font-weight: 500;
  color: rgba(138, 138, 138, 0.7);

  margin-left: 14px;
}

.NonValue{
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  font-size: 16px;
  font-weight: 500;
  color: rgba(138, 138, 138, 0.7);
}

</style>