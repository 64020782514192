<template>
    <div class="ChartGroup">
      <span class="Title">Pay instruments, % TDC (rounded)</span>
      <div class="CompanyChart">
        <div>
          <div class="LineChart">
              <span class="Title">Selected sample (avg.)</span>
              <div class="Chart" v-if="(SampleChart.cash != null && SampleChart.cash != 0) || (SampleChart.equity != null && SampleChart.equity != 0)">
                  <div class="Line">
                    <div v-tooltip="'Cash payments'" v-if="SampleChart.cash != null && SampleChart.cash != 0" :style="{ 'width': Math.round((100/ (SampleChart.cash + SampleChart.equity)) * SampleChart.cash) + '%' }" class="LineValue Green">
                      <span v-if="Math.round((100/ (SampleChart.cash + SampleChart.equity)) * SampleChart.cash) > 6" class="Value">{{Math.round((100/ (SampleChart.cash + SampleChart.equity)) * SampleChart.cash)}}%</span>
                    </div>  
                    <div v-tooltip="'Equity allocations'" v-if="SampleChart.equity != null && SampleChart.equity != 0" :style="{ 'width': Math.round((100/ (SampleChart.cash + SampleChart.equity)) * SampleChart.equity) + '%' }" class="LineValue Gold">
                      <span v-if="Math.round((100/ (SampleChart.cash + SampleChart.equity)) * SampleChart.equity) > 6" class="Value">{{Math.round((100/ (SampleChart.cash + SampleChart.equity)) * SampleChart.equity)}}%</span>
                    </div>         
                  </div>
              </div>
              <div class="Chart" v-else>
                  <span class="NullValue">not comparable</span>
              </div>
          </div>
          <div class="LineChart" v-for="Position of CompanyChart.positions" :key="Position.id">
              <span class="Title">{{CompanyChart.companyName}}</span>
              <div class="Chart" v-if="(Position.cash != null && Position.cash != 0) || (Position.equity != null && Position.equity != 0)">
                  <div class="Line">
                    <div v-tooltip="'Cash payments'" v-if="Position.cash != null && Position.cash != 0" :style="{ 'width': Math.round(Position.cash * 100) + '%' }" class="LineValue Green">
                      <span v-if="Math.round(Position.cash * 100) > 6" class="Value">{{Math.round(Position.cash * 100)}}%</span>
                    </div>  
                    <div v-tooltip="'Equity allocations'" v-if="Position.equity != null && Position.equity != 0" :style="{ 'width': Math.round(Position.equity * 100) + '%' }" class="LineValue Gold">
                      <span v-if="Math.round(Position.equity * 100) > 6" class="Value">{{Math.round(Position.equity * 100)}}%</span>
                    </div>         
                  </div>
              </div>
              <div class="Chart" v-else>
                  <span class="NullValue">not comparable</span>
              </div>
          </div>
          <div class="Legends"> 
            <div class="Legend">
              <span class="Dot Green"></span>
              <span class="Title">Cash payments</span>
            </div>
            <div class="Legend">
              <span class="Dot Gold"></span>
              <span class="Title">Equity allocations</span>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props:{
      CompanyChart: Object,
      GroupChart: Array
  },
  data(){
    return{
      SampleChart: {}
    }
  },
  mounted(){
    this.UpdateChart();
  },
  methods:{
    UpdateChart: function(){
      var tempCash = 0;
      var tempEquity = 0;

      var number = 0;

      this.GroupChart.forEach(chart => {
        chart.positions.forEach(position => {
          tempCash += position.cash;
          tempEquity += position.equity;

          number++;
        });
      });

      this.SampleChart = {
        cash: tempCash / number,
        equity: tempEquity / number
      }
    }
  },
  watch:{
    "CompanyChart": { 
      handler: function () {
        this.UpdateChart();
      },
      deep: true
    }, 
    "GroupChart": { 
      handler: function () {
        this.UpdateChart();
      },
      deep: true
    }
  }

}
</script>

<style scoped>

.ChartGroup{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  margin-right: 24px;
  margin-bottom: 34px;
}

.ChartGroup .Title{
  font-size: 14px;
  font-weight: 600;
  color: #333333;

  margin-bottom: 11px;
}

.ChartGroup .CompanyChart{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;

  position: relative;

  width: 340px;
  height: 300px;

  box-shadow: 4px 4px 20px rgb(0 0 0 / 9%);

  padding: 16px;
  padding-top: 30px;

  margin-bottom: 24px;
}

.Chart{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  position: relative;

  width: 340px;
  height: 100%;
}

.Chart .NullValue{
  width: 100%;

  text-align: center;

  font-size: 14px;
  line-height: 25px;
  font-weight: 500;
  color: #7d7979;

  background-color: #F4F4F4;
}

.LineChart{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 40px;
}

.LineChart .Title{
  font-size: 14px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(138, 138, 138, 0.7);
}

.LineChart .Line{
  display: flex;
  align-items: center;

  position: relative;

  width: 100%;
  height: 25px;
}

.LineChart .LineValue{
  display: flex;
  align-items: center;
  justify-content: center;
    
  width: 0%;
  height: 100%;

  border-right: 1px solid #FFFFFF;

  transition: 0.5s;    
}

.LineChart .LineValue{
  transition: 0.2s;
}

.LineChart .LineValue:hover{
  transform: scale(1.1);

  border: none;
  z-index: 2;

  transition: 0.2s;
}

.Gold{
  background: #B8A15A;
}

.Green{
  background: #22AD9D
}

.LineChart .Value{
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
}

.NonValue{
  display: flex;
  align-items: center;
  justify-content: center;

  height: 85%;

  font-size: 16px;
  font-weight: 500;
  color: rgba(138, 138, 138, 0.7);
}

.Legends{    
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  position: absolute;

  width: 340px;

  bottom: 16px;
}

.Legend{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  width: 50%;
  
  margin-bottom: 10px;
}

.Legend .Dot{
  width: 10px;
  height: 10px;

  border-radius: 50%;

  margin-right: 8px;
}

.Legend .Title{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;

  color: #CCCCCC;
  
  margin-bottom: 0px;
}

</style>