import { createRouter, createWebHistory } from "vue-router";

import AuthenticationPage from "@/pages/Authentication/AuthenticationPage.vue";
import CompensationPage from "@/pages/Compensation/CompensationPage.vue";
import BODCompensationPage from "@/pages/Compensation/BOD/BODCompensationPage.vue";
import EXBCompensationPage from "@/pages/Compensation/EXB/EXBCompensationPage.vue";
import SOPCompensationPage from "@/pages/Compensation/SOP/SOPCompensationPage.vue";
import NetworkGraphPage from "@/pages/NetworkGraph.vue";
import AdminPage from "@/pages/Admin/AdminPage.vue";
import UserEditor from "@/pages/Admin/UserEditor.vue";

export default createRouter({
  mode: "hash",
  base: process.env.VUE_ROUTER_BASE,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  history: createWebHistory(),
  routes: [
    {
      name: "AuthenticationPage",
      path: "/login",
      component: AuthenticationPage,
    },
    {
      name: "CompensationPage",
      path: "/",
      redirect: "BOD_compensation",
      component: CompensationPage,
      children: [
        {
          name: "BODCompensationPage",
          path: "BOD_compensation",
          component: BODCompensationPage,
        },
        {
          name: "EXBCompensationPage",
          path: "EXP_compensation",
          component: EXBCompensationPage,
        },
        {
          name: "SOPCompensationPage",
          path: "SOP_results",
          component: SOPCompensationPage,
        },
      ],
    },
    {
      name: "NetworkGraphPage",
      path: "/Network_SPI_Boards",
      component: NetworkGraphPage,
    },
    {
      name: "AdminPage",
      path: "/admin",
      redirect: "/admin/users",
      component: AdminPage,
      children: [{ name: "UserEditor", path: "users", component: UserEditor }],
    },
    //{ name: 'Videos', path: '/videos', component: VideosPage }
  ],
});
