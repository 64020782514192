<template>
  <div class="AuthenticationPageView">
    <div class="Content">
      <div class="LogoBlock">
        <img class="Logo" :src="require('@/assets/LongLogo.png')" />
        <span class="Image"
          >Get Your Compensation Intelligence & Analytics</span
        >
      </div>
      <div class="FormBlock">
        <div class="LogInBlock" :style="{ height: LogInOpen ? '50%' : '15%' }">
          <div
            class="InputBlock"
            :style="{
              height: LogInOpen ? '66px' : '0px',
              'margin-bottom': LogInOpen ? '32px' : '0px',
            }"
          >
            <span
              >Email address
              <span v-if="LogInError" class="ErrorText"
                >incorrect email or password</span
              >
              <span v-if="ForgotPasswordError" class="ErrorText"
                >incorrect email</span
              ></span
            >
            <input
              v-model="LogInForm.Email"
              type="email"
              :class="{ Error: LogInError || ForgotPasswordError }"
            />
          </div>
          <div
            class="InputBlock"
            :style="{
              height: LogInOpen ? '66px' : '0px',
              'margin-bottom': LogInOpen ? '32px' : '0px',
            }"
          >
            <span>Password</span>
            <input
              v-model="LogInForm.Password"
              type="password"
              :class="{ Error: LogInError }"
            />
          </div>
          <div
            @click="ForgotPassword"
            class="ResetPass"
            :style="{
              height: LogInOpen ? '16px' : '0px',
              'margin-bottom': LogInOpen ? '-25px' : '0px',
            }"
          >
            <span>Forgot password?</span>
          </div>
          <div class="Button" @click="OpenLogIn">
            <span>Log in</span>
          </div>
        </div>
        <div
          class="LineBlock"
          :style="{ height: RegistrationOpen ? '10%' : '20%' }"
        >
          <span class="Line"></span>
          <span class="LineText">Or</span>
          <span class="Line"></span>
        </div>
        <div
          class="RegistrationBlock"
          :style="{ height: RegistrationOpen ? '75%' : '30%' }"
        >
          <div
            class="InputBlock"
            :style="{
              height: RegistrationOpen ? '66px' : '0px',
              'margin-bottom': RegistrationOpen ? '16px' : '0px',
            }"
          >
            <span>First name</span>
            <input
              v-model="RegistrationForm.FirstName"
              type="text"
              :class="{ Error: RegistrationError }"
            />
          </div>
          <div
            class="InputBlock"
            :style="{
              height: RegistrationOpen ? '66px' : '0px',
              'margin-bottom': RegistrationOpen ? '16px' : '0px',
            }"
          >
            <span>Last name</span>
            <input
              v-model="RegistrationForm.LastName"
              type="text"
              :class="{ Error: RegistrationError }"
            />
          </div>
          <div
            class="InputBlock"
            :style="{
              height: RegistrationOpen ? '66px' : '0px',
              'margin-bottom': RegistrationOpen ? '16px' : '0px',
            }"
          >
            <span>Company</span>
            <input
              v-model="RegistrationForm.Company"
              type="text"
              :class="{ Error: RegistrationError }"
            />
          </div>
          <div
            class="InputBlock"
            :style="{
              height: RegistrationOpen ? '66px' : '0px',
              'margin-bottom': RegistrationOpen ? '16px' : '0px',
            }"
          >
            <span>Position</span>
            <input
              v-model="RegistrationForm.Position"
              type="text"
              :class="{ Error: RegistrationError }"
            />
          </div>
          <div
            class="InputBlock"
            :style="{
              height: RegistrationOpen ? '66px' : '0px',
              'margin-bottom': RegistrationOpen ? '16px' : '0px',
            }"
          >
            <span
              >Email address
              <span v-if="EmailError" class="ErrorText"
                >this e-mail is already in use
              </span></span
            >
            <input
              v-model="RegistrationForm.Email"
              id="email"
              type="email"
              :class="{ Error: RegistrationError || EmailError }"
            />
          </div>
          <div
            class="Button"
            @click="OpenRegistration"
            :style="{ 'margin-top': RegistrationOpen ? '15px' : '0px' }"
          >
            <span>Apply for a subscription</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      LogInOpen: true,
      RegistrationOpen: false,

      LogInForm: {
        Email: "",
        Password: "",
        MacInfo: navigator.userAgent,
      },
      RegistrationForm: {
        FirstName: "",
        LastName: "",
        Company: "",
        Position: "",
        Email: "",
      },

      LogInError: false,
      ForgotPasswordError: false,
      RegistrationError: false,
      EmailError: false,
    };
  },
  methods: {
    OpenLogIn: function () {
      this.LogInError = false;
      this.ForgotPasswordError = false;

      if (this.RegistrationOpen == true) {
        this.RegistrationOpen = false;
        this.LogInOpen = true;
      } else {
        if (
          this.LogInForm.Email != null &&
          this.LogInForm.Email != "" &&
          this.LogInForm.Password != null &&
          this.LogInForm.Password != ""
        ) {
          document.getElementById("Loading").style.display = "flex";
          axios
            .put(this.$api_url + "HCMWeb/SingIn", this.LogInForm)
            .then((response) => {
              if (
                response.data != null &&
                response.data != undefined &&
                response.data.sessionId != undefined &&
                response.data.userId != undefined
              ) {
                localStorage.session = response.data.sessionId;
                localStorage.userId = response.data.userId;

                this.$emit("UpdateData");
                this.$emit("CheckSession");

                this.$router.push({ name: "CompensationPage" });
              } else {
                this.LogInError = true;
                localStorage.clear();
              }
              document.getElementById("Loading").style.display = "none";
            })
            .catch((error) => {
              console.log(error);
              document.getElementById("Loading").style.display = "none";
            });
        } else {
          this.LogInError = true;
        }
      }
    },
    OpenRegistration: function () {
      this.RegistrationError = false;
      this.EmailError = false;

      if (this.LogInOpen == true) {
        this.LogInOpen = false;
        this.RegistrationOpen = true;
      } else {
        if (
          this.RegistrationForm.FirstName != null &&
          this.RegistrationForm.FirstName != "" &&
          this.RegistrationForm.LastName != null &&
          this.RegistrationForm.LastName != "" &&
          this.RegistrationForm.Company != null &&
          this.RegistrationForm.Company != "" &&
          this.RegistrationForm.Position != null &&
          this.RegistrationForm.Position != "" &&
          this.RegistrationForm.Email != null &&
          this.RegistrationForm.Email != ""
        ) {
          document.getElementById("Loading").style.display = "flex";

          axios
            .put(
              this.$api_url + "HCMWeb/PreRegistration",
              this.RegistrationForm
            )
            .then((response) => {
              if (response.data == true) {
                this.$alert(
                  "Your request has been successfully submitted. We will contact you via e-mail as soon as possible.",
                  "Success",
                  "success"
                );
              } else {
                this.EmailError = true;
              }

              document.getElementById("Loading").style.display = "none";
            })
            .catch((error) => {
              console.log(error);
              document.getElementById("Loading").style.display = "none";
            });
        } else {
          this.RegistrationError = true;
        }
      }
    },
    ForgotPassword: function () {
      this.LogInError = false;
      this.ForgotPasswordError = false;

      if (this.LogInForm.Email != null && this.LogInForm.Email != "") {
        document.getElementById("Loading").style.display = "flex";
        axios
          .get(
            this.$api_url +
              "HCMWeb/ForgotPassword?Email=" +
              this.LogInForm.Email
          )
          .then((response) => {
            if (response.data == true) {
              this.$alert(
                "Your request has been successfully sent, we will get back to you as soon as possible.",
                "Success",
                "success"
              );
            } else {
              this.ForgotPasswordError = true;
            }

            document.getElementById("Loading").style.display = "none";
          })
          .catch((error) => {
            console.log(error);
            document.getElementById("Loading").style.display = "none";
          });
      } else {
        this.ForgotPasswordError = true;
      }
    },
  },
};
</script>

<style scoped>
.AuthenticationPageView {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: rgb(50, 102, 141);
}

.Content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;

  width: 1000px;
  height: 800px;
}

.LogoBlock {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;

  width: 50%;
  height: 100%;
}

.LogoBlock .Logo {
  padding: 13px 0px;
  width: 380px;
}

.LogoBlock .Logo {
  padding-top: 13px;
  width: 380px;
}

.LogoBlock .Image {
  font-size: 38px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.749);

  width: 95%;
  height: 100%;

  padding-left: 30px;
  padding-top: 100px;

  background-image: url("@/assets/AuthenticationImage.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.FormBlock {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  width: 41%;
  height: 87.7%;

  padding: 0px 46px;

  background-color: #ffffff;
}

.FormBlock .LineBlock {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;

  width: 100%;

  transition: 0.5s;
}

.FormBlock .LineText {
  font-size: 14px;
  font-weight: 500;
  color: #8a8a8a;

  padding: 0 25px;
}

.FormBlock .Line {
  width: 50%;
  height: 1px;
  border-bottom: 1px solid #175a5180;
}

.LogInBlock {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;

  width: 100%;

  transition: 0.5s;
}

.RegistrationBlock {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  width: 100%;

  transition: 0.5s;
}

.InputBlock {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  position: relative;

  width: 100%;
  height: 66px;

  margin-bottom: 32px;

  transition: 0.5s;

  overflow: hidden;
}

.InputBlock span {
  font-size: 16px;
  font-weight: 700;

  margin-bottom: 8px;
}

.InputBlock input {
  background: 0 0;
  border: 1px solid #c4c4c4;
  outline: 0;
  font-size: 14px;
  padding: 8px 12px;

  width: 93.6%;
}

.ErrorText {
  color: #ec5050;
  margin-left: 30px;
}

.Error {
  border: 1px solid #ec5050 !important;
}

.ResetPass {
  display: flex;

  width: 100%;

  font-size: 14px;
  font-weight: 400;
  color: #c4c4c4;

  margin-top: -25px;

  transition: 0.5s;

  overflow-y: hidden;
}

.ResetPass span {
  cursor: pointer;
}

.ResetPass span:hover {
  text-decoration-line: underline;
}

.Button {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  font-size: 16px;
  font-weight: 700;

  color: #000000;

  background-color: transparent;

  border: 1px solid #bca15a;

  width: 100%;
  height: 48px;

  margin-top: 60px;

  cursor: pointer;
}

.Button span {
  z-index: 2;

  transition: 0.1s;

  cursor: pointer;
}

.Button::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  content: "";
  background: #bca15a;
  transition: transform 0.3s;

  transform-origin: right top;
  transform: scale(0, 1);
}

.Button:hover {
  color: #fff;

  transition: 0.1s;
}

.Button:hover::before {
  transform-origin: left top;
  transform: scale(1, 1);

  z-index: 0;
}
</style>
