<template>
    <div class="ChartGroup">
        <span class="Title">Total direct compensation (TDC), CHFk</span>
        <div class="CompanyChart">
            <div v-if="GroupChart!= undefined && GroupChart.length > 0">
                <div class="LineChart">
                    <span class="Title">Upper quartile</span>
                    <div class="Chart" v-tooltip="SelectedList" v-if="UpperQuartile != undefined && UpperQuartile != null">
                        <div class="Line">
                            <div :style="{ 'width': (100 / MaxValue) * UpperQuartile + '%' }" class="LineValue"></div>
                            <span class="Value">{{Math.round(UpperQuartile / 1000).toLocaleString('ru-RU')}}</span>
                        </div>
                    </div>
                    <div class="Chart" v-else v-tooltip="CompanyChart.companyName">
                        <span class="NullValue">not comparable</span>
                    </div>
                </div>
                <div class="LineChart">
                    <span class="Title">Median</span>
                    <div class="Chart" v-tooltip="SelectedList" v-if="Median != undefined && Median != null">
                        <div class="Line">
                            <div :style="{ 'width': (100 / MaxValue) * Median + '%' }" class="LineValue"></div>
                            <span class="Value">{{Math.round(Median / 1000).toLocaleString('ru-RU')}}</span>
                        </div>
                    </div>
                    <div class="Chart" v-else v-tooltip="CompanyChart.companyName">
                        <span class="NullValue">not comparable</span>
                    </div>
                </div>
                <div class="LineChart">
                    <span class="Title">Low quartile</span>
                    <div class="Chart" v-tooltip="SelectedList" v-if="LowerQuartile != undefined && LowerQuartile != null">
                        <div class="Line">
                            <div :style="{ 'width': (100 / MaxValue) * LowerQuartile + '%' }" class="LineValue"></div>
                            <span class="Value">{{Math.round(LowerQuartile / 1000).toLocaleString('ru-RU')}}</span>
                        </div>
                    </div>
                    <div class="Chart" v-else v-tooltip="CompanyChart.companyName">
                        <span class="NullValue">not comparable</span>
                    </div>
                </div>
                <div v-tooltip="CompanyChart.companyName" class="CompanyLine" :style="{ 'left': (85 / MaxValue) * CompanyChart.positions[0].tdc + '%' }" v-if="CompanyChart != undefined && CompanyChart != null && CompanyChart.positions != undefined && CompanyChart.positions != null && CompanyChart.positions.length > 0 && CompanyChart.positions[0].tdc != 0">
                  <span class="Value">{{Math.round((CompanyChart.positions[0].tdc / 1000)).toLocaleString('ru-RU')}}</span>
                </div>
            </div>
            <div v-else class="NonValue">Not available</div>
        </div>
    </div>
</template>

<script>
export default {
  props:{
      CompanyChart: Object,
      GroupChart: Array
  },
  data(){
    return{
      Median: 0,
      UpperQuartile: 0,
      LowerQuartile: 0,

      MaxValue: 0,

      SelectedList: ""
    }
  },
  mounted(){
    this.UpdateChart();
  },
  methods:{
    UpdateChart: function(){
      var ArrayValues = []

      this.GroupChart.forEach(chart => {
        chart.positions.forEach(position => {
          if(position.tdc != null && position.tdc != 0)
            ArrayValues.push(position.tdc);
        });
      });

      if(ArrayValues.length > 0){
        this.UpperQuartile = Math.round(this.quantile(ArrayValues, 0.75));
        this.Median = Math.round(this.quantile(ArrayValues, 0.50));
        this.LowerQuartile = Math.round(this.quantile(ArrayValues, 0.25));
      }
      else{ 
        this.UpperQuartile = null;
        this.Median = null;
        this.LowerQuartile = null;
      }

      if(this.CompanyChart != undefined && this.CompanyChart != null && this.CompanyChart.positions != undefined && this.CompanyChart.positions != null && this.CompanyChart.positions.length > 0 && this.CompanyChart.positions[0].tdc != 0)
        this.MaxValue = this.CompanyChart.positions[0].tdc > this.UpperQuartile ? this.CompanyChart.positions[0].tdc : this.UpperQuartile;
      else
        this.MaxValue = this.UpperQuartile

      this.SelectedList = ""

      var Selected = JSON.parse(localStorage.getItem("selectedFilters") || '[]');

      Selected.forEach(element => {   
        this.SelectedList += element.name + ", ";
      });

      if(this.SelectedList != ""){
        this.SelectedList = this.SelectedList.substring(0, this.SelectedList.length - 2)
      }
    },
    quantile: function(arr, q){
      const asc = arr => arr.sort((a, b) => a - b);

      const sorted = asc(arr);
      const pos = (sorted.length - 1) * q;
      const base = Math.floor(pos);
      const rest = pos - base;
      if (sorted[base + 1] !== undefined) {
          return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
      } else {
          return sorted[base];
      }
    }
  },
  watch:{
    "CompanyChart": { 
      handler: function () {
        this.UpdateChart();
      },
      deep: true
    }, 
    "GroupChart": { 
      handler: function () {
        this.UpdateChart();
      },
      deep: true
    }
  }
}
</script>

<style scoped>

.ChartGroup{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  margin-right: 24px;
  margin-bottom: 34px;
}

.ChartGroup .Title{
  font-size: 14px;
  font-weight: 600;
  color: #333333;

  margin-bottom: 11px;
}

.ChartGroup .CompanyChart{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;

  position: relative;

  width: 340px;
  height: 300px;

  box-shadow: 4px 4px 20px rgb(0 0 0 / 9%);

  padding: 16px;
  padding-top: 30px;

  margin-bottom: 24px;
}

.Chart{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  position: relative;

  width: 340px;
  height: 100%;
}

.Chart .NullValue{
  width: 100%;

  text-align: center;

  font-size: 14px;
  line-height: 25px;
  font-weight: 500;
  color: #7d7979;

  background-color: #F4F4F4;
}

.LineChart{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 40px;
}

.LineChart .Title{
  font-size: 14px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(138, 138, 138, 0.7);
}

.LineChart .Line{
  display: flex;
  align-items: center;

  position: relative;

  width: 100%;
  height: 25px;
}

.LineChart .LineValue{
  width: 0%;
  height: 100%;

  transition: 0.5s;    
  
  background: #C0C3D2;
}

.LineChart .Chart{
  transition: 0.2s;
}

.LineChart .Chart:hover{
  transform: scale(1.05);

  transition: 0.2s;
}

.LineChart .Value{
  font-size: 14px;
  font-weight: 500;
  color: rgba(138, 138, 138, 0.7);

  margin-left: 14px;
}

.CompanyLine{
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  position: absolute;

  height: 284px;
  bottom: 16px;

  border-left: 2px solid #bca15a;

  z-index: 10;

  transition: 0.5s;
}

.CompanyLine .Value{
  color: #bca15a;
  font-size: 14px;
  font-weight: 600;

  margin-left: 8px;
}

.CompanyLine:hover{
  transform: scale(1.05);

  transition: 0.2s;
}

.NonValue{
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  font-size: 16px;
  font-weight: 500;
  color: rgba(138, 138, 138, 0.7);
}

</style>