<template>
    <div class="ChartGroup">
      <span class="Title">Pay instruments, % TDC (rounded)</span>
      <div class="CompanyChart">
        <div>
          <div class="LineChart">
              <span class="Title">Selected sample (avg.)</span>
              <div class="Chart" v-if="(SampleChart.baseSalary != null && SampleChart.baseSalary != 0) || (SampleChart.immediteBonus != null && SampleChart.immediteBonus != 0) || (SampleChart.deferredBonus != null && SampleChart.deferredBonus != 0) || (SampleChart.lti != null && SampleChart.lti != 0)">
                  <div class="Line">
                      <div v-tooltip="{content: GetTypeProcentGroup(SampleChart.baseSalary) > 6 ? 'Base salary' : 'Base salary: ' + GetTypeProcentGroup(SampleChart.baseSalary) + '%'}" v-if="SampleChart.baseSalary != null && SampleChart.baseSalary != 0" :style="{ 'width': GetTypeProcentGroup(SampleChart.baseSalary) + '%' }" class="LineValue Red">
                        <span v-if="GetTypeProcentGroup(SampleChart.baseSalary) > 6" class="Value">{{GetTypeProcentGroup(SampleChart.baseSalary)}}%</span>
                      </div>             
                      <div v-tooltip="{content: GetTypeProcentGroup(SampleChart.immediteBonus) > 6 ? 'Immediate bonus' : 'Immediate bonus: ' + GetTypeProcentGroup(SampleChart.immediteBonus) + '%'}" v-if="SampleChart.immediteBonus != null && SampleChart.immediteBonus != 0" :style="{ 'width': GetTypeProcentGroup(SampleChart.immediteBonus) + '%' }" class="LineValue Green">
                        <span v-if="GetTypeProcentGroup(SampleChart.immediteBonus) > 6" class="Value">{{GetTypeProcentGroup(SampleChart.immediteBonus)}}%</span>
                      </div>  
                      <div v-tooltip="{content: GetTypeProcentGroup(SampleChart.deferredBonus) > 6 ? 'Deferred bonus' : 'Deferred bonus: ' + GetTypeProcentGroup(SampleChart.deferredBonus) + '%'}" v-if="SampleChart.deferredBonus != null && SampleChart.deferredBonus != 0" :style="{ 'width': GetTypeProcentGroup(SampleChart.deferredBonus) + '%' }" class="LineValue Blue">
                        <span v-if="GetTypeProcentGroup(SampleChart.deferredBonus) > 6" class="Value">{{GetTypeProcentGroup(SampleChart.deferredBonus)}}%</span>
                      </div>  
                      <div v-tooltip="{content: GetTypeProcentGroup(SampleChart.lti) > 6 ? 'Stand-alone LTI' : 'Stand-alone LTI: ' + GetTypeProcentGroup(SampleChart.lti) + '%'}" v-if="SampleChart.lti != null && SampleChart.lti != 0" :style="{ 'width': GetTypeProcentGroup(SampleChart.lti) + '%' }" class="LineValue Grey">
                        <span v-if="GetTypeProcentGroup(SampleChart.lti) > 6" class="Value">{{GetTypeProcentGroup(SampleChart.lti)}}%</span>
                      </div>        
                  </div>
              </div>
              <div class="Chart" v-else>
                  <span class="NullValue">not comparable</span>
              </div>
          </div>
          <div class="LineChart" v-for="Position of CompanyChart.positions" :key="Position.id">
              <span class="Title">{{CompanyChart.companyName}}</span>
              <div class="Chart" v-if="(Position.baseSalary != null && Position.baseSalary != 0) || (Position.immediteBonus != null && Position.immediteBonus != 0) || (Position.deferredBonus != null && Position.deferredBonus != 0) || (Position.lti != null && Position.lti != 0)">
                  <div class="Line">
                      <div v-tooltip="{content: GetTypeProcentCompany(Position, Position.baseSalary) > 6 ? 'Base salary' : 'Base salary: ' + GetTypeProcentCompany(Position, Position.baseSalary) + '%'}" v-if="Position.baseSalary != null && Position.baseSalary != 0" :style="{ 'width': GetTypeProcentCompany(Position, Position.baseSalary) + '%' }" class="LineValue Red">
                        <span v-if="GetTypeProcentCompany(Position, Position.baseSalary) > 6" class="Value">{{GetTypeProcentCompany(Position, Position.baseSalary)}}%</span>
                      </div>             
                      <div v-tooltip="{content: GetTypeProcentCompany(Position, Position.immediteBonus) > 6 ? 'Immediate bonus' : 'Immediate bonus: ' + GetTypeProcentCompany(Position, Position.immediteBonus) + '%'}" v-if="Position.immediteBonus != null && Position.immediteBonus != 0" :style="{ 'width': GetTypeProcentCompany(Position, Position.immediteBonus) + '%' }" class="LineValue Green">
                        <span v-if="GetTypeProcentCompany(Position, Position.immediteBonus) > 6" class="Value">{{GetTypeProcentCompany(Position, Position.immediteBonus)}}%</span>
                      </div>  
                      <div v-tooltip="{content: GetTypeProcentCompany(Position, Position.deferredBonus) > 6 ? 'Deferred bonus' : 'Deferred bonus: ' + GetTypeProcentCompany(Position, Position.deferredBonus) + '%'}" v-if="Position.deferredBonus != null && Position.deferredBonus != 0" :style="{ 'width': GetTypeProcentCompany(Position, Position.deferredBonus) + '%' }" class="LineValue Blue">
                        <span v-if="GetTypeProcentCompany(Position, Position.deferredBonus) > 6" class="Value">{{GetTypeProcentCompany(Position, Position.deferredBonus)}}%</span>
                      </div>  
                      <div v-tooltip="{content: GetTypeProcentCompany(Position, Position.lti) > 6 ? 'Stand-alone LTI' : 'Stand-alone LTI: ' + GetTypeProcentCompany(Position, Position.lti) + '%'}" v-if="Position.lti != null && Position.lti != 0" :style="{ 'width': GetTypeProcentCompany(Position, Position.lti) + '%' }" class="LineValue Grey">
                        <span v-if="GetTypeProcentCompany(Position, Position.lti) > 6" class="Value">{{GetTypeProcentCompany(Position, Position.lti)}}%</span>
                      </div>        
                  </div>
              </div>
              <div class="Chart" v-else>
                  <span class="NullValue">not comparable</span>
              </div>
          </div>
          <div class="Legends"> 
            <div class="Legend">
              <span class="Dot Red"></span>
              <span class="Title">Base salary</span>
            </div>
            <div class="Legend">
              <span class="Dot Green"></span>
              <span class="Title">Immediate bonus</span>
            </div>
            <div class="Legend">
              <span class="Dot Blue"></span>
              <span class="Title">Deferred bonus</span>
            </div>
            <div class="Legend">
              <span class="Dot Grey"></span>
              <span class="Title">Stand-alone LTI</span>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props:{
      CompanyChart: Object,
      GroupChart: Array
  },
  data(){
    return{
      SampleChart: {}
    }
  },
  mounted(){
    this.UpdateChart();
  },
  methods:{
    UpdateChart: function(){
      var tempBaseSalary = 0;
      var tempImmediteBonus = 0;
      var tempDeferredBonus = 0;
      var tempLTI = 0;

      var number = 0;

      this.GroupChart.forEach(chart => {
        chart.positions.forEach(position => {
          tempBaseSalary += position.baseSalary;
          tempImmediteBonus += position.immediteBonus;
          tempDeferredBonus += position.deferredBonus;
          tempLTI += position.lti;

          number++;
        });
      });

      this.SampleChart = {
        baseSalary: tempBaseSalary / number,
        immediteBonus: tempImmediteBonus / number,
        deferredBonus: tempDeferredBonus / number,
        lti: tempLTI / number,
      }
    },
    GetTypeProcentCompany: function(Position, ValueType){
      return Math.round((100 / (Position.baseSalary + Position.immediteBonus + Position.deferredBonus + Position.lti)) * ValueType)
    },
    GetTypeProcentGroup: function(ValueType){
      return Math.round((100 / (this.SampleChart.baseSalary + this.SampleChart.immediteBonus + this.SampleChart.deferredBonus + this.SampleChart.lti)) * ValueType)
    }
  },
  watch:{
    "CompanyChart": { 
      handler: function () {
        this.UpdateChart();
      },
      deep: true
    }, 
    "GroupChart": { 
      handler: function () {
        this.UpdateChart();
      },
      deep: true
    }
  }

}
</script>

<style scoped>

.ChartGroup{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  margin-right: 24px;
  margin-bottom: 34px;
}

.ChartGroup .Title{
  font-size: 14px;
  font-weight: 600;
  color: #333333;

  margin-bottom: 11px;
}

.ChartGroup .CompanyChart{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;

  position: relative;

  width: 340px;
  height: 300px;

  box-shadow: 4px 4px 20px rgb(0 0 0 / 9%);

  padding: 16px;
  padding-top: 30px;

  margin-bottom: 24px;
}

.Chart{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  position: relative;

  width: 340px;
  height: 100%;
}

.Chart .NullValue{
  width: 100%;

  text-align: center;

  font-size: 14px;
  line-height: 25px;
  font-weight: 500;
  color: #7d7979;

  background-color: #F4F4F4;
}

.LineChart{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 40px;
}

.LineChart .Title{
  font-size: 14px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(138, 138, 138, 0.7);
}

.LineChart .Line{
  display: flex;
  align-items: center;

  position: relative;

  width: 100%;
  height: 25px;
}

.LineChart .LineValue{
  display: flex;
  align-items: center;
  justify-content: center;
    
  width: 0%;
  height: 100%;

  border-right: 1px solid #FFFFFF;

  transition: 0.5s;    
}

.LineChart .LineValue{
  transition: 0.2s;
}

.LineChart .LineValue:hover{
  transform: scale(1.1);

  border: none;
  z-index: 2;

  transition: 0.2s;
}

.Red{
  background: #EC9750;
}

.Green{
  background: #22AD9D
}

.Blue{
  background: #405068;
}

.Grey{
  background: #789ECF;
}

.LineChart .Value{
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
}

.NonValue{
  display: flex;
  align-items: center;
  justify-content: center;

  height: 85%;

  font-size: 16px;
  font-weight: 500;
  color: rgba(138, 138, 138, 0.7);
}

.Legends{    
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  position: absolute;

  width: 340px;

  bottom: 16px;
}

.Legend{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  width: 50%;
  
  margin-bottom: 10px;
}

.Legend .Dot{
  width: 10px;
  height: 10px;

  border-radius: 50%;

  margin-right: 8px;
}

.Legend .Title{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;

  color: #CCCCCC;
  
  margin-bottom: 0px;
}

</style>