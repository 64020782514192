<template>
    <div class="ChartGroup">
        <span class="Title">Pay instruments, % TDC (rounded)</span>
        <div class="CompanyChart">
            <div v-if="CompanyChart != undefined">
                <div class="LineChart" v-for="Position of CompanyChart.positions" :key="Position.id">
                    <span class="Title">{{Position.position}}</span>
                    <div class="Chart" v-if="(Position.baseSalary != null && Position.baseSalary != 0) || (Position.immediteBonus != null && Position.immediteBonus != 0) || (Position.deferredBonus != null && Position.deferredBonus != 0) || (Position.lti != null && Position.lti != 0)">
                        <div class="Line">
                            <div v-tooltip="{content: GetTypeProcent(Position, Position.baseSalary) > 6 ? CompanyChart.companyName + ' - Base salary' : CompanyChart.companyName + ' - Base salary: ' + GetTypeProcent(Position, Position.baseSalary) + '%'}" v-if="Position.baseSalary != null && Position.baseSalary != 0" :style="{ 'width': GetTypeProcent(Position, Position.baseSalary) + '%' }" class="LineValue Red">
                              <span v-if="GetTypeProcent(Position, Position.baseSalary) > 6" class="Value">{{GetTypeProcent(Position, Position.baseSalary)}}%</span>
                            </div>             
                            <div v-tooltip="{content: GetTypeProcent(Position, Position.immediteBonus) > 6 ? CompanyChart.companyName + ' - Immediate bonus' : CompanyChart.companyName + ' - Immediate bonus: ' + GetTypeProcent(Position, Position.immediteBonus) + '%'}" v-if="Position.immediteBonus != null && Position.immediteBonus != 0" :style="{ 'width': GetTypeProcent(Position, Position.immediteBonus) + '%' }" class="LineValue Green">
                              <span v-if="GetTypeProcent(Position, Position.immediteBonus) > 6" class="Value">{{GetTypeProcent(Position, Position.immediteBonus)}}%</span>
                            </div>  
                            <div v-tooltip="{content: GetTypeProcent(Position, Position.deferredBonus) > 6 ? CompanyChart.companyName + ' - Deferred bonus' : CompanyChart.companyName + ' - Deferred bonus: ' + GetTypeProcent(Position, Position.deferredBonus) + '%'}" v-if="Position.deferredBonus != null && Position.deferredBonus != 0" :style="{ 'width': GetTypeProcent(Position, Position.deferredBonus) + '%' }" class="LineValue Blue">
                              <span v-if="GetTypeProcent(Position, Position.deferredBonus) > 6" class="Value">{{GetTypeProcent(Position, Position.deferredBonus)}}%</span>
                            </div>  
                            <div v-tooltip="{content: GetTypeProcent(Position, Position.lti) > 6 ? CompanyChart.companyName + ' - Stand-alone LTI' : CompanyChart.companyName + ' - Stand-alone LTI: ' + GetTypeProcent(Position, Position.lti) + '%'}" v-if="Position.lti != null && Position.lti != 0" :style="{ 'width': GetTypeProcent(Position, Position.lti) + '%' }" class="LineValue Grey">
                              <span v-if="GetTypeProcent(Position, Position.lti) > 6" class="Value">{{GetTypeProcent(Position, Position.lti)}}%</span>
                            </div>        
                        </div>
                    </div>
                    <div class="Chart" v-else v-tooltip="CompanyChart.companyName">
                        <span class="NullValue">not comparable</span>
                    </div>
                </div>
                <div class="Legends"> 
                  <div class="Legend">
                    <span class="Dot Red"></span>
                    <span class="Title">Base salary</span>
                  </div>
                  <div class="Legend">
                    <span class="Dot Green"></span>
                    <span class="Title">Immediate bonus</span>
                  </div>
                  <div class="Legend">
                    <span class="Dot Blue"></span>
                    <span class="Title">Deferred bonus</span>
                  </div>
                  <div class="Legend">
                    <span class="Dot Grey"></span>
                    <span class="Title">Stand-alone LTI</span>
                  </div>
                </div>
            </div>
            <div v-else class="NonValue">Not available</div>
            <div v-if="CompanyChart != undefined && (CompanyChart.positions == undefined || CompanyChart.positions.length == 0)" class="NonValue">Not available</div>
        </div>
    </div>
</template>

<script>
export default {
  props:{
      CompanyChart: Object
  },
  methods:{
    GetTypeProcent: function(Position, ValueType){
      return Math.round((100 / (Position.baseSalary + Position.immediteBonus + Position.deferredBonus + Position.lti)) * ValueType)
    }
  }
}
</script>

<style scoped>

.ChartGroup{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  margin-right: 24px;
  margin-bottom: 34px;
}

.ChartGroup .Title{
  font-size: 14px;
  font-weight: 600;
  color: #333333;

  margin-bottom: 11px;
}

.ChartGroup .CompanyChart{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;

  position: relative;

  width: 340px;
  height: 300px;

  box-shadow: 4px 4px 20px rgb(0 0 0 / 9%);

  padding: 16px;
  padding-top: 30px;

  margin-bottom: 24px;
}

.Chart{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  position: relative;

  width: 340px;
  height: 100%;
}

.Chart .NullValue{
  width: 100%;

  text-align: center;

  font-size: 14px;
  line-height: 25px;
  font-weight: 500;
  color: #7d7979;

  background-color: #F4F4F4;
}

.LineChart{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 40px;
}

.LineChart .Title{
  font-size: 14px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(138, 138, 138, 0.7);
}

.LineChart .Line{
  display: flex;
  align-items: center;

  position: relative;

  width: 100%;
  height: 25px;
}

.LineChart .LineValue{
  display: flex;
  align-items: center;
  justify-content: center;
    
  width: 0%;
  height: 100%;

  border-right: 1px solid #FFFFFF;

  transition: 0.5s;    
}

.LineChart .LineValue{
  transition: 0.2s;
}

.LineChart .LineValue:hover{
  transform: scale(1.1);

  border: none;
  z-index: 2;

  transition: 0.2s;
}

.Red{
  background: #EC9750;
}

.Green{
  background: #22AD9D
}

.Blue{
  background: #405068;
}

.Grey{
  background: #789ECF;
}

.LineChart .Value{
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
}

.NonValue{
  display: flex;
  align-items: center;
  justify-content: center;

  height: 85%;

  font-size: 16px;
  font-weight: 500;
  color: rgba(138, 138, 138, 0.7);
}

.Legends{    
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  position: absolute;

  width: 340px;

  bottom: 16px;
}

.Legend{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  width: 50%;
  
  margin-bottom: 10px;
}

.Legend .Dot{
  width: 10px;
  height: 10px;

  border-radius: 50%;

  margin-right: 8px;
}

.Legend .Title{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;

  color: #CCCCCC;
  
  margin-bottom: 0px;
}

</style>