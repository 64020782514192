<template>
  <div class="AdminPage">
    <div class="AdminNavPanel">
      <div class="UserPanel">
        <div class="UserIcon">
          <img :src="require('@/assets/Admin/AdminIcon.svg')" />
        </div>
        <span class="UserName">{{ User.firstName }} {{ User.lastName }}</span>
      </div>
      <div class="NavPanel">
        <div
          class="NavItem"
          :class="{ Active: NavItem.active }"
          v-for="NavItem of NavMenu"
          :key="NavItem.id"
          @mouseenter="NavItem.icon = NavItem.icon_hover"
          @mouseleave="NavItem.icon = NavItem.icon_common"
        >
          <img
            v-if="NavItem.active"
            :src="require('@/assets/Admin/' + NavItem.icon_hover + '.svg')"
          />
          <img
            v-else
            :src="require('@/assets/Admin/' + NavItem.icon + '.svg')"
          />
          <span>{{ NavItem.name }}</span>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      User: {},
      NavMenu: [
        {
          id: 1,
          name: "Users",
          page: "UserEditor",
          icon: "UsersIcon",
          icon_common: "UsersIcon",
          icon_hover: "UsersIconActive",
          active: true,
        },
        {
          id: 2,
          name: "Receipts (in work)",
          icon: "ReceiptIcon",
          icon_common: "ReceiptIcon",
          icon_hover: "ReceiptIconActive",
          page: "",
          active: false,
        },
        {
          id: 3,
          name: "Data (in work)",
          icon: "DataIcon",
          icon_common: "DataIcon",
          icon_hover: "DataIconActive",
          page: "",
          active: false,
        },
      ],
    };
  },
  created() {
    this.User = JSON.parse(localStorage.getItem("currentUser") || "{}");
  },
};
</script>

<style scoped>
.AdminPage {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  width: 100%;
  height: calc(96% - 100px);
}

.AdminNavPanel {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: flex-start;

  width: 200px;
  height: 100%;

  padding-top: 30px;
}

.UserPanel {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: flex-start;

  width: 100%;
}

.UserIcon {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 10px 10px 10px;

  width: 70px;
  height: 70px;

  border-radius: 50%;
  border: 5px solid rgb(50, 102, 141);
}

.UserPanel img {
  width: 60px;
  height: 60px;

  object-fit: cover;
}

.UserName {
  margin-top: 16px;

  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.NavPanel {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;

  padding-top: 30px;

  overflow-x: hidden;
}

.NavItem {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  width: 100%;

  padding-top: 12px;
  padding-bottom: 12px;

  padding-left: 30px;

  border-bottom: 1px solid #c4c4c4;

  cursor: pointer;

  transition: 0.3s;
}

.NavItem img {
  width: 20px;
  height: 20px;

  margin-right: 8px;
}

.NavItem span {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.Active,
.NavItem:hover {
  background: rgba(0, 0, 0, 0.1);
}
</style>
