<template>
  <div class="NavBar">
    <img class="Logo" :src="require('@/assets/LongLogo.png')" />
    <div class="NavMenu">
      <span
        class="NavItem"
        @click="OpenPage(NavItem)"
        v-for="NavItem of NavMenu"
        :key="NavItem.id"
        :class="{ Active: NavItem.active }"
        >{{ NavItem.name }}</span
      >
      <div class="Buttons">
        <span
          class="Exit"
          @click="LogOut"
          @mouseenter="ExitIcon = 'ExitIconActive'"
          @mouseleave="ExitIcon = 'ExitIcon'"
        >
          <img :src="require('@/assets/NavBar/' + ExitIcon + '.svg')" />
          Log out
        </span>
        <span
          class="Exit"
          @click="GoToHCM"
          @mouseenter="WebIcon = 'WebIconActive'"
          @mouseleave="WebIcon = 'WebIcon'"
        >
          <img :src="require('@/assets/NavBar/' + WebIcon + '.svg')" />
          Visit hcm.com
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      NavMenu: [],
      ExitIcon: "ExitIcon",
      WebIcon: "WebIcon",
    };
  },
  created() {
    this.NavMenu = [
      {
        id: 1,
        name: "Board of Directors'\ncompensation",
        page: "BODCompensationPage",
        active: true,
      },
      {
        id: 2,
        name: "Executive\ncompensation",
        page: "EXBCompensationPage",
        active: false,
      },
      {
        id: 3,
        name: "Say-on-Pay\nresults",
        page: "SOPCompensationPage",
        active: false,
      },
      {
        id: 4,
        name: "Network of Board\nmembers (SPI)",
        page: "NetworkGraphPage",
        active: false,
      },
    ];

    if (localStorage.menuActiveId <= this.NavMenu.length) {
      if (localStorage.menuActiveId) {
        this.NavMenu.forEach((element) => {
          element.active = false;
        });

        this.NavMenu.find(
          (x) => x.id == localStorage.menuActiveId
        ).active = true;

        this.$router.push({
          name: this.NavMenu.find((x) => x.id == localStorage.menuActiveId)
            .page,
        });
      }
    } else {
      localStorage.menuActiveId = 1;
    }
  },
  mounted() {
    this.CheckUser();
  },
  methods: {
    OpenPage: function (NavItem) {
      this.NavMenu.forEach((element) => {
        element.active = false;
      });

      if (NavItem.id == 5) {
        window.open("https://getcompia.z1.web.core.windows.net/", "_blank");
        return;
      }

      NavItem.active = true;

      localStorage.menuActiveId = NavItem.id;

      this.$router.push({ name: NavItem.page });
    },
    LogOut: function () {
      axios
        .get(
          this.$api_url + "HCMWeb/RemoveSession?Session=" + localStorage.session
        )
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
      localStorage.clear();
      this.$router.push({ name: "AuthenticationPage" });
    },
    GoToHCM: function () {
      window.open("https://hcm.com/", "_blank");
    },
    CheckUser: function () {
      if (localStorage.currentUser != undefined) {
        var User = JSON.parse(localStorage.getItem("currentUser") || "{}");
        String.prototype.replaceAt = function (index, replacement) {
          return (
            this.substring(0, index) +
            replacement +
            this.substring(index + replacement.length)
          );
        };
        var AdminCheck = User.userId.toLowerCase().replace(/[^a-z]/gi, "");

        var num = 1;

        for (let i = 3; i < AdminCheck.length; i++) {
          if (i % 3 == 0) {
            if (num >= User.firstName.length) num = 1;
            AdminCheck = AdminCheck.replaceAt(i, User.firstName[num]);
            num++;
          }
        }

        if (User.company == "HCM") {
          this.NavMenu = [
            {
              id: 1,
              name: "Board of Directors'\ncompensation",
              page: "BODCompensationPage",
              active: true,
            },
            {
              id: 2,
              name: "Executive\ncompensation",
              page: "EXBCompensationPage",
              active: false,
            },
            {
              id: 3,
              name: "Say-on-Pay\nresults",
              page: "SOPCompensationPage",
              active: false,
            },
            {
              id: 4,
              name: "Network of Board\nmembers (SPI)",
              page: "NetworkGraphPage",
              active: false,
            },
            {
              id: 5,
              name: "Switch to \ngetCompIA app",
              page: "OpenApp",
              active: false,
            },
          ];
        }

        if (User.admin == AdminCheck) {
          this.NavMenu = [
            {
              id: 1,
              name: "Board of Directors'\ncompensation",
              page: "BODCompensationPage",
              active: true,
            },
            {
              id: 2,
              name: "Executive\ncompensation",
              page: "EXBCompensationPage",
              active: false,
            },
            {
              id: 3,
              name: "Say-on-Pay\nresults",
              page: "SOPCompensationPage",
              active: false,
            },
            {
              id: 4,
              name: "Network of Board\nmembers (SPI)",
              page: "NetworkGraphPage",
              active: false,
            },
            {
              id: 5,
              name: "Switch to \ngetCompIA app",
              page: "OpenApp",
              active: false,
            },
            {
              id: 6,
              name: "Admin panel",
              page: "AdminPage",
              active: false,
            },
          ];
        }
      }
    },
  },
};
</script>

<style scoped>
.NavBar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  background-color: rgb(50, 102, 141);

  height: 78px;

  padding: 10px 25px;
  padding-right: 0px;
}

.Logo {
  height: 50px;
}

.NavMenu {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.4);
}

.NavItem {
  display: flex;
  align-items: center;

  text-align: left;
  white-space: pre-wrap;

  margin-right: 50px;

  cursor: pointer;

  transition: 0.2s;
}

.NavItem:hover {
  color: #a89f64;

  transition: 0.2s;
}

.Active {
  color: #a89f64;
}

.Buttons {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  height: 98px;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
}

.Exit {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  width: 160px;
  padding: 12.2px 0px 12.2px 15px;

  cursor: pointer;

  color: rgba(255, 255, 255, 0.4);
}

.Exit:last-child {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.Exit:hover {
  color: #a89f64;

  background: rgba(0, 0, 0, 0.3);

  transition: 0.2s;
}

.Exit img {
  width: 24px;
  height: 24px;

  margin-right: 10px;
}
</style>
