<template>
  <div class="Filters">
    <div class="CustomFilter">
      <div
        :class="{ Collapse: $route.name != 'BODCompensationPage' }"
        class="SelectAGM"
      >
        <span>Select position</span>
        <v-select
          class="CompanyDropDown"
          v-model="BODPosition"
          :options="BODPositions"
        ></v-select>
      </div>
      <div
        :class="{ Collapse: $route.name != 'EXBCompensationPage' }"
        class="SelectAGM"
      >
        <span>Select position</span>
        <v-select
          class="CompanyDropDown"
          v-model="EXBPosition"
          :options="EXBPositions"
        ></v-select>
      </div>
      <div
        :class="{ Collapse: $route.name != 'SOPCompensationPage' }"
        class="SelectEXBPosition"
      >
        <span>Select AGM Season</span>
        <v-select
          class="CompanyDropDown"
          v-model="AGMYear"
          :options="AGMYearList"
        ></v-select>
      </div>
    </div>
    <div class="SelectCompany">
      <span>Select a company</span>
      <v-select
        class="CompanyDropDown"
        v-model="SelectCompanyId"
        :options="CompanyList"
        label="name"
        :reduce="(company) => company.id"
      ></v-select>
    </div>
    <div class="SelectCompanies">
      <div class="SelectCompany">
        <span>Select sample of companies</span>
        <v-select
          class="CompanyDropDown"
          v-model="TempSelectCompanyId"
          :options="CompanyList"
          label="name"
          :reduce="(company) => company.id"
        ></v-select>
      </div>
      <div class="CompanyList">
        <div
          class="CompanyBlock"
          v-for="Block of SortedCompanyList"
          :key="Block.title"
        >
          <div
            class="CompanyBox"
            v-for="Company of Block.companies"
            :key="Company.id"
            @click="SelectingCompany(Company)"
          >
            <span
              class="CompanyCheckbox"
              :class="{
                SelectedCompany: SelectedCompanyList.find(
                  (x) => x.id == Company.id
                ),
              }"
            ></span>
            <span
              class="CompanyName"
              v-tooltip="Company.name"
              :class="{
                SelectedCompanyName: SelectedCompanyList.find(
                  (x) => x.id == Company.id
                ),
                Off:
                  (SelectedIndexList.length > 0 ||
                    SelectedIndustryList.length > 0) &&
                  CompanyList.find((x) => x.id == Company.id).indexs.find(
                    (x) => SelectedIndexList.find((y) => y.id == x) != undefined
                  ) == undefined,
              }"
              >{{ Company.name }}</span
            >
          </div>
        </div>
      </div>
      <div class="TitleBlock">
        <span class="Title">Filter by</span>
        <span @click="ClearFilters" class="ClearAll">Clear all</span>
      </div>
      <div class="SelectIndex">
        <span class="Title">Index</span>
        <div class="IndexList">
          <div
            class="IndexBlock"
            @click="SelectingIndex(Index)"
            v-for="Index of IndexList"
            :key="Index.id"
            :class="{
              SelectedIndex: SelectedIndexList.find((x) => x.id == Index.id),
            }"
          >
            <span>{{ Index.name }}</span>
          </div>
        </div>
      </div>
      <div class="SelectIndustry">
        <div class="IndustryTitle">
          <span class="Title">Industry</span>
          <span
            @click="OpenIndustryCheck = false"
            v-if="OpenIndustryCheck"
            class="OpenBtn"
            >↓</span
          >
          <span @click="OpenIndustryCheck = true" v-else class="OpenBtn"
            >↑</span
          >
        </div>
        <div class="IndustryList" :class="{ Open: OpenIndustryCheck }">
          <div
            class="IndustryBlock"
            @click="SelectingIndustry(Industry)"
            v-for="Industry of IndustryList"
            :key="Industry.id"
          >
            <span
              class="IndustryCheckbox"
              :class="{
                SelectedIndustry: SelectedIndustryList.find(
                  (x) => x.id == Industry.id
                ),
              }"
            ></span>
            <span
              class="IndustryName"
              :class="{
                SelectedIndustryName: SelectedIndustryList.find(
                  (x) => x.id == Industry.id
                ),
              }"
              >{{ Industry.name }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  props: {
    CompanyList: Array,
    IndexList: Array,
    IndustryList: Array,
    SelectMainCompany: Object,
  },
  data() {
    return {
      AGMYear: null,
      AGMYearList: [],

      EXBPosition: "CEO",
      EXBPositions: ["CEO", "Other executives (avg.)"],

      BODPosition: "Board Chair",
      BODPositions: ["Board Chair", "Board Vice Chair", "Other Board member"],

      SelectCompanyId: null,
      SelectCompany: null,

      SortedCompanyList: [],

      TempSelectCompanyId: null,
      StorageSelectedCompanyList: [],

      SelectedCompanyList: [],
      SelectedIndexList: [],
      SelectedIndustryList: [],

      OpenIndustryCheck: true,
    };
  },
  created() {
    this.SortByAlphabet();

    this.AGMYear = new Date().getFullYear();

    this.AGMYearList.push(new Date().getFullYear());
    this.AGMYearList.push(new Date().getFullYear() - 1);
    this.AGMYearList.push(new Date().getFullYear() - 2);

    this.StorageSelectedCompanyList = JSON.parse(
      localStorage.getItem("storageSelectedCompanyList") || "[]"
    );

    this.SelectedCompanyList = JSON.parse(
      localStorage.getItem("selectedCompanyList") || "[]"
    );
    this.SelectedIndexList = JSON.parse(
      localStorage.getItem("selectedIndexList") || "[]"
    );
    this.SelectedIndustryList = JSON.parse(
      localStorage.getItem("selectedIndustryList") || "[]"
    );
  },
  methods: {
    SortByAlphabet: function () {
      this.CompanyList.forEach((company) => {
        if (
          this.SortedCompanyList.find((x) => x.title == company.name[0]) ==
          undefined
        ) {
          var newBlock = {
            title: company.name[0],
            companies: [],
          };

          newBlock.companies.push(company);
          this.SortedCompanyList.push(newBlock);
        } else {
          this.SortedCompanyList.find(
            (x) => x.title == company.name[0]
          ).companies.push(company);
        }
      });

      this.SortedCompanyList.sort((a, b) => a.title.localeCompare(b.title));
    },
    UpdateLists: function (
      SelectedCompanyList,
      SelectedIndexList,
      SelectedIndustryList,
      Item
    ) {
      this.StorageSelectedCompanyList = this.StorageSelectedCompanyList.filter(
        (x) => x.id != Item.id && x.name != Item.name
      );

      this.SelectedCompanyList = SelectedCompanyList;
      this.SelectedIndexList = SelectedIndexList;
      this.SelectedIndustryList = SelectedIndustryList;

      this.StorageSelectedCompanyList.forEach((Company) => {
        if (
          this.SelectedCompanyList.find((x) => x.id == Company.id) == undefined
        ) {
          this.SelectedCompanyList.push(Company);
        }
      });
    },
    SelectingCompany: function (Company) {
      if (
        this.SelectedCompanyList.find((x) => x.id == Company.id) == undefined
      ) {
        this.SelectedCompanyList.push(Company);
        this.StorageSelectedCompanyList.push(Company);
      } else {
        this.SelectedCompanyList = this.SelectedCompanyList.filter(
          (x) => x.id != Company.id
        );
        this.StorageSelectedCompanyList =
          this.StorageSelectedCompanyList.filter((x) => x.id != Company.id);
      }

      this.$emit(
        "UpdateSelectedLists",
        this.SelectedCompanyList,
        this.SelectedIndexList,
        this.SelectedIndustryList
      );
    },
    SelectingIndex: function (Index) {
      if (this.SelectedIndexList.find((x) => x.id == Index.id) == undefined) {
        this.SelectedIndexList.push(Index);
      } else {
        this.SelectedIndexList = this.SelectedIndexList.filter(
          (x) => x.id != Index.id
        );
      }

      this.UpdateSelectedCompanyList();
    },
    SelectingIndustry: function (Industry) {
      if (
        this.SelectedIndustryList.find((x) => x.id == Industry.id) == undefined
      ) {
        this.SelectedIndustryList.push(Industry);
      } else {
        this.SelectedIndustryList = this.SelectedIndustryList.filter(
          (x) => x.id != Industry.id
        );
      }

      this.UpdateSelectedCompanyList();
    },
    UpdateSelectedCompanyList: function () {
      this.SelectedCompanyList = [];

      this.SelectedCompanyList = this.CompanyList.filter((Company) => {
        var flagIndex = false;
        var flagIndustry = false;

        if (
          this.SelectedIndexList.length == 0 &&
          this.SelectedIndustryList.length == 0
        ) {
          return false;
        }

        if (this.SelectedIndexList.length > 0) {
          Company.indexs.forEach((element) => {
            if (
              this.SelectedIndexList.find((x) => x.id == element) != undefined
            ) {
              flagIndex = true;
            }
          });
        } else {
          flagIndex = true;
        }

        if (this.SelectedIndustryList.length > 0) {
          if (
            this.SelectedIndustryList.find((x) => x.id == Company.industryId) !=
            undefined
          ) {
            flagIndustry = true;
          }
        } else {
          flagIndustry = true;
        }

        return flagIndex && flagIndustry;
      });

      this.StorageSelectedCompanyList.forEach((Company) => {
        if (
          this.SelectedCompanyList.find((x) => x.id == Company.id) == undefined
        ) {
          this.SelectedCompanyList.push(Company);
        }
      });

      this.$emit(
        "UpdateSelectedLists",
        this.SelectedCompanyList,
        this.SelectedIndexList,
        this.SelectedIndustryList
      );
    },
    ClearAll: function () {
      this.SelectedCompanyList = [];
      this.SelectedIndexList = [];
      this.SelectedIndustryList = [];
      this.StorageSelectedCompanyList = [];
    },
    ClearFilters: function () {
      this.SelectedIndexList = [];
      this.SelectedIndustryList = [];

      this.UpdateSelectedCompanyList();
    },
  },
  watch: {
    AGMYear: function () {
      this.$emit("ChangeAGMYear", this.AGMYear);
    },
    EXBPosition: function () {
      this.$emit("ChangeEXBPosition", this.EXBPosition);
    },
    BODPosition: function () {
      this.$emit("ChangeBODPosition", this.BODPosition);
    },
    CompanyList: function () {
      this.SortByAlphabet();
    },
    SelectCompanyId: function (value) {
      if (value != null) {
        this.SelectCompany = this.CompanyList.find((x) => x.id == value);
      } else {
        this.SelectCompany = null;
      }

      this.$emit("ChangeMainCompany", this.SelectCompany);
    },
    SelectMainCompany: function () {
      this.SelectCompany = this.SelectMainCompany;

      if (this.SelectMainCompany != null) {
        this.SelectCompanyId = this.SelectMainCompany.id;
      } else {
        this.SelectCompanyId = null;
      }
    },
    TempSelectCompanyId: function (value) {
      if (value != null) {
        this.SelectingCompany(this.CompanyList.find((x) => x.id == value));

        this.TempSelectCompanyId = null;
      }
    },
    StorageSelectedCompanyList: {
      handler: function () {
        localStorage.storageSelectedCompanyList = JSON.stringify(
          this.StorageSelectedCompanyList
        );
      },
      deep: true,
    },
    SelectedCompanyList: {
      handler: function () {
        localStorage.selectedCompanyList = JSON.stringify(
          this.SelectedCompanyList
        );
      },
      deep: true,
    },
    SelectedIndexList: {
      handler: function () {
        localStorage.selectedIndexList = JSON.stringify(this.SelectedIndexList);
      },
      deep: true,
    },
    SelectedIndustryList: {
      handler: function () {
        localStorage.selectedIndustryList = JSON.stringify(
          this.SelectedIndustryList
        );
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.Filters {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.CustomFilter {
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;

  margin-bottom: 32px;

  width: 220px;
  height: 60px;
}

.SelectAGM {
  position: absolute;

  left: 0;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;

  width: 220px;
  height: 60px;

  transition: 0.5s;
}

.SelectAGM span {
  font-size: 16px;
  font-weight: 600;
  color: #000000;

  padding-bottom: 8px;
}

.SelectEXBPosition {
  position: absolute;

  left: 0;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;

  width: 220px;
  height: 60px;

  transition: 0.5s;
}

.SelectEXBPosition span {
  font-size: 16px;
  font-weight: 600;
  color: #000000;

  padding-bottom: 8px;
}

.Collapse {
  left: -250px;

  transition: 0.5s;
}

.SelectCompany {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;

  margin-bottom: 32px;
}

.SelectCompany span {
  font-size: 16px;
  font-weight: 600;
  color: #000000;

  padding-bottom: 8px;
}

.CompanyDropDown {
  width: 220px;

  font-size: 14px;
  font-weight: 500;
  color: #8a8a8ab3;
}

.SelectCompanies {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.SelectCompanies .SelectCompany {
  margin-bottom: 0px;
}

.CompanyList {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;

  padding-top: 16px;

  height: 220px;

  overflow-y: scroll;

  border-bottom: 2px solid #8a8a8ad2;
}

.CompanyBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  width: 220px;

  padding-top: 16px;
  padding-bottom: 4px;

  border-bottom: 1px solid #8a8a8ab3;
}

.CompanyBlock:first-child {
  padding-top: 0px;
}
.CompanyBlock:last-child {
  border-bottom: none;
}

.CompanyBox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  width: 100px;

  margin-right: 10px;

  margin-bottom: 14px;

  cursor: pointer;
}

.CompanyBox .CompanyName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  height: 14px;

  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);

  padding-left: 8px;
}

.CompanyBox .SelectedCompanyName {
  color: #bca15a;
}

.CompanyBox .Off {
  color: #8a8a8ab3;
}

.CompanyBox:hover .CompanyName {
  background: rgb(223, 209, 175);
  background: -moz-linear-gradient(
    90deg,
    rgba(223, 209, 175, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(223, 209, 175, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(223, 209, 175, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dfd1af",endColorstr="#ffffff",GradientType=1);
}

.CompanyBox .CompanyCheckbox {
  position: relative;

  min-width: 12px;
  min-height: 12px;

  max-width: 12px;
  max-height: 12px;

  border: 1px solid #bca15a;
}

.CompanyBox .SelectedCompany::after {
  content: " ";

  position: absolute;

  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;

  background-color: #bca15a;
}

.TitleBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  padding-top: 24px;
}

.TitleBlock .Title {
  font-size: 16px;
  font-weight: 600;
}

.TitleBlock .ClearAll {
  font-size: 16px;
  font-weight: 600;

  padding: 0px 8px;

  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #bca15a;
  cursor: pointer;
}

.TitleBlock .ClearAll:hover {
  background: #dfe2e4;
}

.SelectIndex {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;

  padding-top: 16px;
}

.SelectIndex .Title {
  font-size: 15px;
  margin-bottom: 8px;
}

.SelectIndex .IndexList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  width: 220px;

  overflow: hidden;
}

.SelectIndex .IndexList .IndexBlock {
  margin-right: 8px;
  margin-bottom: 8px;

  padding: 9px;

  font-size: 12px;
  font-weight: 500;
  color: #8a8a8ab3;

  border: 1px solid #8a8a8ab3;

  cursor: pointer;
}

.SelectIndex .IndexList .IndexBlock:hover {
  background: #dfe2e4;
}

.SelectedIndex {
  color: #bca15a !important;
  border: 1px solid #bca15a !important;
}

.SelectedIndex span::before {
  content: "✓";
  margin-right: 4px;
}

.SelectIndustry {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;

  padding-top: 16px;
}

.IndustryTitle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 8px;
}

.SelectIndustry .IndustryTitle .Title {
  font-size: 15px;
}

.SelectIndustry .IndustryTitle .OpenBtn {
  font-size: 14px;

  text-align: center;

  width: 16px;
  height: 16px;

  cursor: pointer;
}

.SelectIndustry .IndustryTitle .OpenBtn:hover {
  background: #dfe2e4;
}

.SelectIndustry .IndustryList {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 0px;
  overflow: hidden;

  padding-top: 8px;

  transition: all 0.3s;
}

.Open {
  height: 100% !important;
  transition: all 0.3s;
}

.SelectIndustry .IndustryList .IndustryBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  width: 200px;

  margin-right: 10px;

  margin-bottom: 14px;

  cursor: pointer;
}

.SelectIndustry .IndustryList .IndustryBlock .IndustryName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  height: 14px;

  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);

  padding-left: 8px;
}

.SelectIndustry .IndustryList .IndustryBlock .SelectedIndustryName {
  color: #bca15a;
}

.SelectIndustry .IndustryList .IndustryBlock .Off {
  color: #8a8a8ab3;
}

.SelectIndustry .IndustryList .IndustryBlock:hover .IndustryName {
  background: rgb(223, 209, 175);
  background: -moz-linear-gradient(
    90deg,
    rgba(223, 209, 175, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(223, 209, 175, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(223, 209, 175, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dfd1af",endColorstr="#ffffff",GradientType=1);
}

.SelectIndustry .IndustryList .IndustryBlock .IndustryCheckbox {
  position: relative;

  min-width: 12px;
  min-height: 12px;

  max-width: 12px;
  max-height: 12px;

  border: 1px solid #bca15a;
}

.SelectIndustry .IndustryList .IndustryBlock .SelectedIndustry::after {
  content: " ";

  position: absolute;

  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;

  background-color: #bca15a;
}
</style>

<style>
.Filters .vs--single .vs__selected {
  display: inline-block;

  max-width: 150px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 14px;
  font-weight: 500;
  color: #8a8a8ab3;
}

.Filters .vs__selected-options {
  align-items: center;
}

.Filters .vs__dropdown-option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  z-index: 10;
}
</style>
