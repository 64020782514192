<template>
    <div class="ChartGroup">
        <span class="Title">Long-term incentive design</span>
        <div class="CompanyChart">
            <span class="Title">Performance considerations (Executive Board)</span>
            <div class="Table">
                <div class="Row BorderBottom">
                    <div class="Cell SpashCell BorderRight" style="width: 160px">
                        <span>at grant</span>
                        <span>at vesting</span>
                    </div>
                    <div class="Cell HeaderCell BorderRight Blue">
                        <span>Deferred bonus</span>
                    </div>
                    <div class="Cell HeaderCell BorderRight Grey">
                        <span>Stand-alone LTI</span>
                    </div>
                </div>
                <div class="Row BorderBottom">
                    <div class="Cell TitleCell BorderRight" style="width: 160px">
                        <span>No condition</span>
                    </div>
                    <div class="Cell ValueCell BorderRight" v-tooltip="{content: SelectCompanyType.find(x=>x == 'dB_Cond') != undefined ? KPICompanyChart.companyName : null}" :class="{ SelectCompanyType: SelectCompanyType.find(x=>x == 'dB_Cond') != undefined }">
                        <span v-if="Chart.dB_Cond != undefined">{{Chart.dB_Cond}}%</span>
                    </div>
                    <div class="Cell ValueCell BorderRight" v-tooltip="{content: SelectCompanyType.find(x=>x == 'sA_Cond') != undefined ? KPICompanyChart.companyName : null}" :class="{ SelectCompanyType: SelectCompanyType.find(x=>x == 'sA_Cond') != undefined }">
                        <span v-if="Chart.sA_Cond != undefined">{{Chart.sA_Cond}}%</span>
                    </div>
                </div>
                <div class="Row BorderBottom">
                    <div class="Cell TitleCell BorderRight" style="width: 160px">
                        <span>Service condition only</span>
                    </div>
                    <div class="Cell ValueCell BorderRight" v-tooltip="{content: SelectCompanyType.find(x=>x == 'dB_Serv') != undefined ? KPICompanyChart.companyName : null}" :class="{ SelectCompanyType: SelectCompanyType.find(x=>x == 'dB_Serv') != undefined }">
                        <span v-if="Chart.dB_Serv != undefined">{{Chart.dB_Serv}}%</span>
                    </div>
                    <div class="Cell ValueCell BorderRight" v-tooltip="{content: SelectCompanyType.find(x=>x == 'sA_Serv') != undefined ? KPICompanyChart.companyName : null}" :class="{ SelectCompanyType: SelectCompanyType.find(x=>x == 'sA_Serv') != undefined }">
                        <span v-if="Chart.sA_Serv != undefined">{{Chart.sA_Serv}}%</span>
                    </div>
                </div>
                <div class="Row BorderBottom">
                    <div class="Cell TitleCell BorderRight" style="width: 160px">
                        <span>Service & performance condition</span>
                    </div>
                    <div class="Cell ValueCell BorderRight" v-tooltip="{content: SelectCompanyType.find(x=>x == 'dB_PSU') != undefined ? KPICompanyChart.companyName : null}" :class="{ SelectCompanyType: SelectCompanyType.find(x=>x == 'dB_PSU') != undefined }">
                        <span v-if="Chart.dB_PSU != undefined">{{Chart.dB_PSU}}%</span>
                    </div>
                    <div class="Cell ValueCell BorderRight" v-tooltip="{content: SelectCompanyType.find(x=>x == 'sA_PSU') != undefined ? KPICompanyChart.companyName : null}" :class="{ SelectCompanyType: SelectCompanyType.find(x=>x == 'sA_PSU') != undefined }">
                        <span v-if="Chart.sA_PSU != undefined">{{Chart.sA_PSU}}%</span>
                    </div>
                </div>
            </div>
            <v-select class="KPIDropDown" v-model="KPIType" :options="KPITypes"></v-select>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
    components:{
        vSelect
    },
    props:{
        KPICompanyChart: Object,
        KPIPlanCompanyChart: Object,
        KPIGroupChart: Object,
        KPIPlanGroupChart: Object,
    },
    data(){
        return{
            Chart: {},
            SelectCompanyType: [],

            KPIType: "Split of granted amounts",
            KPITypes: [ "Split of granted amounts", "Split of number of plans" ]
        }
    },
    mounted(){
        this.UpdateKPIChart();
    },
    methods:{
        UpdateKPIChart: function(){
            this.SelectCompanyType = [];

            if(this.KPICompanyChart != undefined && this.KPICompanyChart != null && this.KPICompanyChart.positions != undefined && this.KPICompanyChart.positions.length > 0){
                if(this.KPICompanyChart.positions[0].dB_Cond != 0 && this.KPICompanyChart.positions[0].dB_Cond != null){
                    this.SelectCompanyType.push("dB_Cond");
                }
                if(this.KPICompanyChart.positions[0].dB_Serv != 0 && this.KPICompanyChart.positions[0].dB_Serv != null){
                    this.SelectCompanyType.push("dB_Serv");
                }
                if(this.KPICompanyChart.positions[0].dB_PSU != 0 && this.KPICompanyChart.positions[0].dB_PSU != null){
                    this.SelectCompanyType.push("dB_PSU");
                }
                if(this.KPICompanyChart.positions[0].sA_Cond != 0 && this.KPICompanyChart.positions[0].sA_Cond != null){
                    this.SelectCompanyType.push("sA_Cond");
                }
                if(this.KPICompanyChart.positions[0].sA_Serv != 0 && this.KPICompanyChart.positions[0].sA_Serv != null){
                    this.SelectCompanyType.push("sA_Serv");
                }
                if(this.KPICompanyChart.positions[0].sA_PSU != 0 && this.KPICompanyChart.positions[0].sA_PSU != null){
                    this.SelectCompanyType.push("sA_PSU");
                }
            }

            this.Chart = {
                dB_Cond: 0,
                dB_Serv: 0,
                dB_PSU: 0,
                sA_Cond: 0,
                sA_Serv: 0,
                sA_PSU: 0
            }

            if(this.KPIGroupChart != undefined && this.KPIGroupChart != null && this.KPIGroupChart.length > 0){
                this.KPIGroupChart.forEach(chart => {
                    chart.positions.forEach(position => {
                        this.Chart.dB_Cond += position.dB_Cond;
                        this.Chart.dB_Serv += position.dB_Serv;
                        this.Chart.dB_PSU += position.dB_PSU;
                        this.Chart.sA_Cond += position.sA_Cond;
                        this.Chart.sA_Serv += position.sA_Serv;
                        this.Chart.sA_PSU += position.sA_PSU;
                    });
                });

                var sum = this.Chart.dB_Cond + this.Chart.dB_Serv + this.Chart.dB_PSU + this.Chart.sA_Cond + this.Chart.sA_Serv + this.Chart.sA_PSU;
                
                this.Chart.dB_Cond = Math.round((100 / sum) * this.Chart.dB_Cond);
                this.Chart.dB_Serv = Math.round((100 / sum) * this.Chart.dB_Serv);
                this.Chart.dB_PSU = Math.round((100 / sum) * this.Chart.dB_PSU);
                this.Chart.sA_Cond = Math.round((100 / sum) * this.Chart.sA_Cond);
                this.Chart.sA_Serv = Math.round((100 / sum) * this.Chart.sA_Serv);
                this.Chart.sA_PSU = Math.round((100 / sum) * this.Chart.sA_PSU);
            }
        },
        UpdateKPIPlanChart: function(){
            if(this.KPIPlanCompanyChart != undefined && this.KPIPlanCompanyChart != null && this.KPIPlanCompanyChart.positions.length > 0){
                if(this.KPIPlanCompanyChart.positions[0].dB_Cond != 0 && this.KPIPlanCompanyChart.positions[0].dB_Cond != null){
                    this.SelectCompanyType.push("dB_Cond");
                }
                if(this.KPIPlanCompanyChart.positions[0].dB_Serv != 0 && this.KPIPlanCompanyChart.positions[0].dB_Serv != null){
                    this.SelectCompanyType.push("dB_Serv");
                }
                if(this.KPIPlanCompanyChart.positions[0].dB_PSU != 0 && this.KPIPlanCompanyChart.positions[0].dB_PSU != null){
                    this.SelectCompanyType.push("dB_PSU");
                }
                if(this.KPIPlanCompanyChart.positions[0].sA_Cond != 0 && this.KPIPlanCompanyChart.positions[0].sA_Cond != null){
                    this.SelectCompanyType.push("sA_Cond");
                }
                if(this.KPIPlanCompanyChart.positions[0].sA_Serv != 0 && this.KPIPlanCompanyChart.positions[0].sA_Serv != null){
                    this.SelectCompanyType.push("sA_Serv");
                }
                if(this.KPIPlanCompanyChart.positions[0].sA_PSU != 0 && this.KPIPlanCompanyChart.positions[0].sA_PSU != null){
                    this.SelectCompanyType.push("sA_PSU");
                }
            }

            this.Chart = {
                dB_Cond: 0,
                dB_Serv: 0,
                dB_PSU: 0,
                sA_Cond: 0,
                sA_Serv: 0,
                sA_PSU: 0
            }

            if(this.KPIPlanGroupChart != undefined && this.KPIPlanGroupChart != null && this.KPIPlanGroupChart.length > 0){
                this.KPIPlanGroupChart.forEach(chart => {
                    chart.positions.forEach(position => {
                        this.Chart.dB_Cond += position.dB_Cond;
                        this.Chart.dB_Serv += position.dB_Serv;
                        this.Chart.dB_PSU += position.dB_PSU;
                        this.Chart.sA_Cond += position.sA_Cond;
                        this.Chart.sA_Serv += position.sA_Serv;
                        this.Chart.sA_PSU += position.sA_PSU;
                    });
                });

                var sum = this.Chart.dB_Cond + this.Chart.dB_Serv + this.Chart.dB_PSU + this.Chart.sA_Cond + this.Chart.sA_Serv + this.Chart.sA_PSU;
                
                this.Chart.dB_Cond = Math.round((100 / sum) * this.Chart.dB_Cond);
                this.Chart.dB_Serv = Math.round((100 / sum) * this.Chart.dB_Serv);
                this.Chart.dB_PSU = Math.round((100 / sum) * this.Chart.dB_PSU);
                this.Chart.sA_Cond = Math.round((100 / sum) * this.Chart.sA_Cond);
                this.Chart.sA_Serv = Math.round((100 / sum) * this.Chart.sA_Serv);
                this.Chart.sA_PSU = Math.round((100 / sum) * this.Chart.sA_PSU);
            }
        }
    },
    watch:{
    "KPIType": function(){
        if(this.KPIType == "Split of granted amounts"){
            this.UpdateKPIChart();
        }
        else {
            this.UpdateKPIPlanChart();
        }
    },
    "KPICompanyChart": { 
      handler: function () {
        if(this.KPIType == "Split of granted amounts"){
            this.UpdateKPIChart();
        }
      },
      deep: true
    }, 
    "KPIPlanCompanyChart": { 
      handler: function () {
        if(this.KPIType == "Split of number of plans"){
            this.UpdateKPIPlanChart();
        }
      },
      deep: true
    },
    "KPIGroupChart": { 
      handler: function () {
        if(this.KPIType == "Split of granted amounts"){
            this.UpdateKPIChart();
        }
      },
      deep: true
    }, 
    "KPIPlanGroupChart": { 
      handler: function () {
        if(this.KPIType == "Split of number of plans"){
            this.UpdateKPIPlanChart();
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

.ChartGroup{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 34px;
}

.ChartGroup .Title{
  font-size: 14px;
  font-weight: 600;
  color: #333333;

  margin-bottom: 11px;
}

.ChartGroup .CompanyChart{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;

  position: relative;

  width: 340px;
  height: 300px;

  box-shadow: 4px 4px 20px rgb(0 0 0 / 9%);

  padding: 16px;
  padding-top: 30px;

  margin-bottom: 24px;
}

.ChartGroup .CompanyChart .Title{
    font-weight: 500;
    font-size: 14px;
    color: rgba(138, 138, 138, 0.7);

    margin-bottom: 0px;
}

.Table{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    margin-top: 24px;
}

.Row{
    display: flex;
}

.Cell{
    width: 90px;
    height: 40px;
}

.SpashCell{    
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;

    position: relative;

    font-weight: 500;
    font-size: 13px;
    color: rgba(138, 138, 138, 0.7);
}

.SpashCell::after{
    content: "";
    position: absolute;
    top: 0;
    border-top: 1px solid rgba(138, 138, 138, 0.85);
    width: 103%;
    transform: rotate( 13.8deg );
    transform-origin: 0% 0%;
}

.SpashCell span:first-child{
    text-align: end;

    padding-right: 8px;
    margin-bottom: 4px;
}

.SpashCell span:last-child{
    padding-left: 4px;
}

.HeaderCell{
    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    text-align: center;
    font-size: 12px;
}

.TitleCell{
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: rgba(138, 138, 138, 0.85);
}

.ValueCell{
    display: flex;
    align-items: center;
    justify-content: center;

    background: #F4F4F4;

    font-size: 14px;
    text-align: center;
    font-weight: 500;
    
    color: rgba(138, 138, 138, 0.85);
}

.BorderTop{
    border-top: 1px solid rgba(138, 138, 138, 0.85);;
}

.BorderLeft{
    border-left: 1px solid rgba(138, 138, 138, 0.85);;
}

.BorderRight{
    border-right: 1px solid rgba(138, 138, 138, 0.85);;
}

.BorderBottom{
    border-bottom: 1px solid rgba(138, 138, 138, 0.85);;
}

.Blue{
  background: #405068;
}

.Grey{
  background: #789ECF;
}

.SelectCompanyType{
    color: #FFFFFF;
    background: rgb(188, 161, 90);
}

.KPIDropDown{
    position: absolute;

    font-size: 12px;
    font-weight: 500;
    color: #8a8a8ab3;

    box-shadow: 4px 4px 20px rgb(0 0 0 / 9%);

    left: 16px;
    right: 16px;
    bottom: 16px;
}

</style>