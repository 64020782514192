<template>
  <div class="Compensation">
    <div class="Note">
      Please select a company and/or sample of companies, as well as the
      {{ $route.name == "SOPCompensationPage" ? "year" : null }}
      {{
        $route.name == "EXBCompensationPage" ||
        $route.name == "BODCompensationPage"
          ? "position"
          : null
      }}
      for the analysis
    </div>
    <SelectedFiltersView
      @ClearAll="ClearAll"
      @RemoveFilterItem="RemoveFilterItem"
      @RemoveMainCompany="SelectMainCompany = null"
      :SelectMainCompany="SelectMainCompany"
      :SelectedFilters="SelectedFilters"
    />
    <div class="MainBlock">
      <FiltersView
        ref="Filters"
        @ChangeBODPosition="ChangeBODPosition"
        @ChangeEXBPosition="ChangeEXBPosition"
        @ChangeAGMYear="ChangeAGMYear"
        @UpdateSelectedLists="UpdateSelectedLists"
        :SelectMainCompany="SelectMainCompany"
        :CompanyList="CompanyList"
        :IndexList="IndexList"
        :IndustryList="IndustryList"
        @ChangeMainCompany="ChangeMainCompany"
      />
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component
            :BODPosition="BODPosition"
            :EXBPosition="EXBPosition"
            :AGMYear="AGMYear"
            :SelectedCompanyList="SelectedCompanyList"
            :SelectMainCompany="SelectMainCompany"
            :is="Component"
          ></component>
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SelectedFiltersView from "@/components/SelectedFiltersView.vue";
import FiltersView from "@/components/FiltersView.vue";

export default {
  components: {
    SelectedFiltersView,
    FiltersView,
  },
  data() {
    return {
      Note: "",

      AGMYear: null,
      EXBPosition: "CEO",
      BODPosition: "Board Chair",

      SelectMainCompany: null,

      SelectedFilters: [],

      SelectedCompanyList: [],
      SelectedIndexList: [],
      SelectedIndustryList: [],

      CompanyList: [],
      IndexList: [],
      IndustryList: [],
    };
  },
  mounted() {
    axios
      .get(this.$api_url + "HCMWeb/GetCompanyList")
      .then((response) => {
        this.CompanyList = response.data;

        this.SelectMainCompany = JSON.parse(
          localStorage.getItem("selectMainCompany") || "[]"
        );

        this.SelectedFilters = JSON.parse(
          localStorage.getItem("selectedFilters") || "[]"
        );

        this.SelectedCompanyList = JSON.parse(
          localStorage.getItem("selectedCompanyList") || "[]"
        );
        this.SelectedIndexList = JSON.parse(
          localStorage.getItem("selectedIndexList") || "[]"
        );
        this.SelectedIndustryList = JSON.parse(
          localStorage.getItem("selectedIndustryList") || "[]"
        );
      })
      .catch((error) => console.log(error));

    axios
      .get(this.$api_url + "HCMWeb/GetIndexList")
      .then((response) => {
        this.IndexList = response.data;
      })
      .catch((error) => console.log(error));

    axios
      .get(this.$api_url + "HCMWeb/GetIndustryList")
      .then((response) => {
        this.IndustryList = response.data;
      })
      .catch((error) => console.log(error));
  },
  methods: {
    ChangeMainCompany: function (Company) {
      this.SelectMainCompany = Company;
    },
    UpdateSelectedLists: function (
      SelectedCompanyList,
      SelectedIndexList,
      SelectedIndustryList
    ) {
      this.SelectedCompanyList = SelectedCompanyList;
      this.SelectedIndexList = SelectedIndexList;
      this.SelectedIndustryList = SelectedIndustryList;

      this.UpdateFilter();
    },
    RemoveFilterItem: function (Item) {
      this.SelectedCompanyList = this.SelectedCompanyList.filter(
        (x) => x.id != Item.id && x.name != Item.name
      );

      this.SelectedIndexList = this.SelectedIndexList.filter(
        (x) => x.id != Item.id && x.name != Item.name
      );

      this.SelectedIndustryList = this.SelectedIndustryList.filter(
        (x) => x.id != Item.id && x.name != Item.name
      );

      this.SelectedCompanyList = this.CompanyList.filter((Company) => {
        var flagIndex = false;
        var flagIndustry = false;

        if (
          this.SelectedIndexList.length == 0 &&
          this.SelectedIndustryList.length == 0
        ) {
          return false;
        }

        if (this.SelectedIndexList.length > 0) {
          Company.indexs.forEach((element) => {
            if (
              this.SelectedIndexList.find((x) => x.id == element) != undefined
            ) {
              flagIndex = true;
            }
          });
        } else {
          flagIndex = true;
        }

        if (this.SelectedIndustryList.length > 0) {
          if (
            this.SelectedIndustryList.find((x) => x.id == Company.industryId) !=
            undefined
          ) {
            flagIndustry = true;
          }
        } else {
          flagIndustry = true;
        }

        return flagIndex && flagIndustry;
      });

      this.$refs.Filters.UpdateLists(
        this.SelectedCompanyList,
        this.SelectedIndexList,
        this.SelectedIndustryList,
        Item
      );

      this.UpdateFilter();
    },
    UpdateFilter: function () {
      this.SelectedFilters = [];

      this.SelectedIndexList.forEach((index) => {
        var tempItem = {
          id: index.id,
          name: index.name,
        };

        this.SelectedFilters.push(tempItem);
      });

      this.SelectedIndustryList.forEach((industry) => {
        var tempItem = {
          id: industry.id,
          name: industry.name,
        };

        this.SelectedFilters.push(tempItem);
      });

      this.SelectedCompanyList.forEach((company) => {
        var flag = false;
        company.indexs.forEach((element) => {
          if (
            this.SelectedIndexList.find((x) => x.id == element) != undefined
          ) {
            flag = true;
          }
        });

        if (
          this.SelectedIndustryList.find((x) => x.id == company.industryId) !=
          undefined
        ) {
          flag = true;
        }

        if (!flag) {
          var tempItem = {
            id: company.id,
            name: company.name,
          };

          this.SelectedFilters.push(tempItem);
        }
      });
    },
    ClearAll: function () {
      this.SelectedCompanyList = [];
      this.SelectedIndexList = [];
      this.SelectedIndustryList = [];
      this.SelectedFilters = [];

      this.SelectMainCompany = null;

      this.$refs.Filters.ClearAll();
    },
    ChangeAGMYear: function (AGMYear) {
      this.AGMYear = AGMYear;
    },
    ChangeEXBPosition: function (EXBPosition) {
      this.EXBPosition = EXBPosition;
    },
    ChangeBODPosition: function (BODPosition) {
      this.BODPosition = BODPosition;
    },
  },
  watch: {
    SelectMainCompany: {
      handler: function () {
        localStorage.selectMainCompany = JSON.stringify(this.SelectMainCompany);
      },
      deep: true,
    },
    SelectedFilters: {
      handler: function () {
        localStorage.selectedFilters = JSON.stringify(this.SelectedFilters);
      },
      deep: true,
    },
    SelectedCompanyList: {
      handler: function () {
        localStorage.selectedCompanyList = JSON.stringify(
          this.SelectedCompanyList
        );
      },
      deep: true,
    },
    SelectedIndexList: {
      handler: function () {
        localStorage.selectedIndexList = JSON.stringify(this.SelectedIndexList);
      },
      deep: true,
    },
    SelectedIndustryList: {
      handler: function () {
        localStorage.selectedIndustryList = JSON.stringify(
          this.SelectedIndustryList
        );
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.Compensation {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.Note {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  font-size: 16px;
  font-weight: 500;
  color: #8a8a8ab3;

  height: 24px;

  padding: 10px 25px;

  background-color: rgba(196, 196, 196, 0.4);
}

.MainBlock {
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  margin-top: 32px;

  padding: 0px 25px;
}

.fade-enter-active {
  transition: all 0.3s;

  position: absolute;
  left: 245px;

  opacity: 0;
}

.fade-leave-active {
  transition: all 0.3s;
  opacity: 1;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
}
</style>
