<template>
  <div class="BODCompensation">
    <div class="Charts">
      <div class="ChartTitle">
        <div class="ChartTitleText">
          <span>DATA AS OF PERFORMANCE YEAR</span>
          <span class="ChartYear">{{ Years[0] }}</span>
        </div>
        <div class="GenerateImage">
          <span @click="GenerateImageYear1">
            Download dashboard
            <img :src="require('@/assets/Compensation/ChartsIcon.svg')" />
          </span>
        </div>
      </div>
      <div class="DownloadChart" id="DownloadChart1">
        <div id="DownloadFiltersNote1"></div>
        <div id="DownloadFiltersView1"></div>
        <div class="Chart" id="ChartsYear1">
          <CompanyTotalChart
            v-if="SelectedCompanyList.length == 0"
            :CompanyChart="CompanyChartYear1"
          />
          <GroupTotalChart
            v-else
            :CompanyChart="CompanyChartYear1"
            :GroupChart="GroupChartYear1"
          />
          <CompanyInstrumentChart
            v-if="SelectedCompanyList.length == 0"
            :CompanyChart="CompanyChartYear1"
          />
          <GroupInstrumentChart
            v-else
            :CompanyChart="CompanyChartYear1"
            :GroupChart="GroupChartYear1"
          />
          <CompanyFeesChart
            v-if="SelectedCompanyList.length == 0"
            :FeesCompanyChart="FeesCompanyChartYear1"
          />
          <GroupFeesChart
            v-else
            :FeesCompanyChart="FeesCompanyChartYear1"
            :FeesGroupChart="FeesGroupChartYear1"
          />
          <div class="Note" id="NoteYear1">
            Note: This analysis includes only non-executive directors with
            full-year mandates during the respective performance period. All
            absolute values are reported in CHF, disclosures in other currencies
            are converted at the respective annual average exchange rates. Where
            possible, equity incentive awards are estimated at their fair value
            at grant, reflecting the expected value of a particular instrument
            considering future payout risks.
          </div>
        </div>
      </div>
      <div class="ChartTitle">
        <div class="ChartTitleText">
          <span>DATA AS OF PERFORMANCE YEAR</span>
          <span class="ChartYear">{{ Years[1] }}</span>
        </div>
        <div class="GenerateImage">
          <span @click="GenerateImageYear2">
            Download dashboard
            <img :src="require('@/assets/Compensation/ChartsIcon.svg')" />
          </span>
        </div>
      </div>
      <div class="DownloadChart" id="DownloadChart2">
        <div id="DownloadFiltersNote2"></div>
        <div id="DownloadFiltersView2"></div>
        <div class="Chart" id="ChartsYear2">
          <CompanyTotalChart
            v-if="SelectedCompanyList.length == 0"
            :CompanyChart="CompanyChartYear2"
          />
          <GroupTotalChart
            v-else
            :CompanyChart="CompanyChartYear2"
            :GroupChart="GroupChartYear2"
          />
          <CompanyInstrumentChart
            v-if="SelectedCompanyList.length == 0"
            :CompanyChart="CompanyChartYear2"
          />
          <GroupInstrumentChart
            v-else
            :CompanyChart="CompanyChartYear2"
            :GroupChart="GroupChartYear2"
          />
          <CompanyFeesChart
            v-if="SelectedCompanyList.length == 0"
            :FeesCompanyChart="FeesCompanyChartYear2"
            :FeesPlanCompanyChart="FeesPlanCompanyChartYear2"
          />
          <GroupFeesChart
            v-else
            :FeesCompanyChart="FeesCompanyChartYear2"
            :FeesPlanCompanyChart="FeesPlanCompanyChartYear2"
            :FeesGroupChart="FeesGroupChartYear2"
            :FeesPlanGroupChart="FeesPlanGroupChartYear2"
          />
          <div class="Note" id="NoteYear2">
            Note: This analysis includes only non-executive directors with
            full-year mandates during the respective performance period. All
            absolute values are reported in CHF, disclosures in other currencies
            are converted at the respective annual average exchange rates. Where
            possible, equity incentive awards are estimated at their fair value
            at grant, reflecting the expected value of a particular instrument
            considering future payout risks.
          </div>
        </div>
      </div>
      <div class="ChartTitle">
        <div class="ChartTitleText">
          <span>DATA AS OF PERFORMANCE YEAR</span>
          <span class="ChartYear">{{ Years[2] }}</span>
        </div>
        <div class="GenerateImage">
          <span @click="GenerateImageYear3">
            Download dashboard
            <img :src="require('@/assets/Compensation/ChartsIcon.svg')" />
          </span>
        </div>
      </div>
      <div class="DownloadChart" id="DownloadChart3">
        <div id="DownloadFiltersNote3"></div>
        <div id="DownloadFiltersView3"></div>
        <div class="Chart" id="ChartsYear3">
          <CompanyTotalChart
            v-if="SelectedCompanyList.length == 0"
            :CompanyChart="CompanyChartYear3"
          />
          <GroupTotalChart
            v-else
            :CompanyChart="CompanyChartYear3"
            :GroupChart="GroupChartYear3"
          />
          <CompanyInstrumentChart
            v-if="SelectedCompanyList.length == 0"
            :CompanyChart="CompanyChartYear3"
          />
          <GroupInstrumentChart
            v-else
            :CompanyChart="CompanyChartYear3"
            :GroupChart="GroupChartYear3"
          />
          <CompanyFeesChart
            v-if="SelectedCompanyList.length == 0"
            :FeesCompanyChart="FeesCompanyChartYear3"
            :FeesPlanCompanyChart="FeesPlanCompanyChartYear3"
          />
          <GroupFeesChart
            v-else
            :FeesCompanyChart="FeesCompanyChartYear3"
            :FeesPlanCompanyChart="FeesPlanCompanyChartYear3"
            :FeesGroupChart="FeesGroupChartYear3"
            :FeesPlanGroupChart="FeesPlanGroupChartYear3"
          />
          <div class="Note" id="NoteYear3">
            Note: This analysis includes only non-executive directors with
            full-year mandates during the respective performance period. All
            absolute values are reported in CHF, disclosures in other currencies
            are converted at the respective annual average exchange rates. Where
            possible, equity incentive awards are estimated at their fair value
            at grant, reflecting the expected value of a particular instrument
            considering future payout risks.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as htmlToImage from "html-to-image";
// eslint-disable-next-line
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

import CompanyTotalChart from "@/components/BOD/CompanyTotalChart.vue";
import CompanyInstrumentChart from "@/components/BOD/CompanyInstrumentChart.vue";
import CompanyFeesChart from "@/components/BOD/CompanyFeesChart.vue";

import GroupTotalChart from "@/components/BOD/GroupTotalChart.vue";
import GroupInstrumentChart from "@/components/BOD/GroupInstrumentChart.vue";
import GroupFeesChart from "@/components/BOD/GroupFeesChart.vue";

export default {
  components: {
    CompanyTotalChart,
    CompanyInstrumentChart,
    CompanyFeesChart,

    GroupTotalChart,
    GroupInstrumentChart,
    GroupFeesChart,
  },
  props: {
    BODPosition: String,
    SelectMainCompany: Object,
    SelectedCompanyList: Array,
  },
  data() {
    return {
      Years: [],

      BOD: [],

      CompanyChartYear1: {},
      CompanyChartYear2: {},
      CompanyChartYear3: {},

      FeesCompanyChartYear1: {},
      FeesCompanyChartYear2: {},
      FeesCompanyChartYear3: {},

      GroupChartYear1: [],
      GroupChartYear2: [],
      GroupChartYear3: [],

      FeesGroupChartYear1: [],
      FeesGroupChartYear2: [],
      FeesGroupChartYear3: [],
    };
  },
  created() {
    this.BOD = JSON.parse(localStorage.getItem("bod") || "[]");

    this.Years.push(new Date().getFullYear() - 1);
    this.Years.push(new Date().getFullYear() - 2);
    this.Years.push(new Date().getFullYear() - 3);

    if (
      this.BOD != undefined &&
      this.BOD.companyData != undefined &&
      this.BOD.companyData.length > 0
    ) {
      this.UpdateCompanyChart();
      this.UpdateGroupChart();
    }
  },
  methods: {
    UpdateCompanyChart: function () {
      this.CompanyChartYear1 = {};
      this.CompanyChartYear2 = {};
      this.CompanyChartYear3 = {};

      this.FeesCompanyChartYear1 = {};
      this.FeesCompanyChartYear2 = {};
      this.FeesCompanyChartYear3 = {};

      if (
        this.SelectMainCompany != undefined &&
        this.SelectMainCompany != null
      ) {
        this.CompanyChartYear1 = this.BOD.companyData.find(
          (x) =>
            x.companyName == this.SelectMainCompany.name &&
            x.year == this.Years[0]
        );
        this.CompanyChartYear2 = this.BOD.companyData.find(
          (x) =>
            x.companyName == this.SelectMainCompany.name &&
            x.year == this.Years[1]
        );
        this.CompanyChartYear3 = this.BOD.companyData.find(
          (x) =>
            x.companyName == this.SelectMainCompany.name &&
            x.year == this.Years[2]
        );

        this.FeesCompanyChartYear1 = this.BOD.feesData.find(
          (x) =>
            x.companyName == this.SelectMainCompany.name &&
            x.year == this.Years[0]
        );
        this.FeesCompanyChartYear2 = this.BOD.feesData.find(
          (x) =>
            x.companyName == this.SelectMainCompany.name &&
            x.year == this.Years[1]
        );
        this.FeesCompanyChartYear3 = this.BOD.feesData.find(
          (x) =>
            x.companyName == this.SelectMainCompany.name &&
            x.year == this.Years[2]
        );

        if (
          this.SelectedCompanyList != undefined &&
          this.SelectedCompanyList != null &&
          this.SelectedCompanyList.length > 0
        ) {
          if (this.CompanyChartYear1 != undefined)
            this.CompanyChartYear1.positions =
              this.CompanyChartYear1.positions.filter(
                (x) => x.position == this.BODPosition
              );
          else this.CompanyChartYear1 = { positions: [] };

          if (this.CompanyChartYear2 != undefined)
            this.CompanyChartYear2.positions =
              this.CompanyChartYear2.positions.filter(
                (x) => x.position == this.BODPosition
              );
          else this.CompanyChartYear2 = { positions: [] };

          if (this.CompanyChartYear3 != undefined)
            this.CompanyChartYear3.positions =
              this.CompanyChartYear3.positions.filter(
                (x) => x.position == this.BODPosition
              );
          else this.CompanyChartYear3 = { positions: [] };

          this.FeesCompanyChartYear1.positions =
            this.FeesCompanyChartYear1.positions.filter(
              (x) => x.position == this.BODPosition
            );
          this.FeesCompanyChartYear2.positions =
            this.FeesCompanyChartYear2.positions.filter(
              (x) => x.position == this.BODPosition
            );
          this.FeesCompanyChartYear3.positions =
            this.FeesCompanyChartYear3.positions.filter(
              (x) => x.position == this.BODPosition
            );
        }
      }
    },
    UpdateGroupChart: function () {
      this.GroupChartYear1 = [];
      this.GroupChartYear2 = [];
      this.GroupChartYear3 = [];

      this.FeesGroupChartYear1 = [];
      this.FeesGroupChartYear2 = [];
      this.FeesGroupChartYear3 = [];

      if (
        this.SelectedCompanyList != undefined &&
        this.SelectedCompanyList != null &&
        this.SelectedCompanyList.length > 0
      ) {
        this.GroupChartYear1 = this.BOD.companyData.filter(
          (x) =>
            this.SelectedCompanyList.find((y) => y.name == x.companyName) !=
              undefined && x.year == this.Years[0]
        );
        this.GroupChartYear2 = this.BOD.companyData.filter(
          (x) =>
            this.SelectedCompanyList.find((y) => y.name == x.companyName) !=
              undefined && x.year == this.Years[1]
        );
        this.GroupChartYear3 = this.BOD.companyData.filter(
          (x) =>
            this.SelectedCompanyList.find((y) => y.name == x.companyName) !=
              undefined && x.year == this.Years[2]
        );

        this.FeesGroupChartYear1 = this.BOD.feesData.filter(
          (x) =>
            this.SelectedCompanyList.find((y) => y.name == x.companyName) !=
              undefined && x.year == this.Years[0]
        );
        this.FeesGroupChartYear2 = this.BOD.feesData.filter(
          (x) =>
            this.SelectedCompanyList.find((y) => y.name == x.companyName) !=
              undefined && x.year == this.Years[1]
        );
        this.FeesGroupChartYear3 = this.BOD.feesData.filter(
          (x) =>
            this.SelectedCompanyList.find((y) => y.name == x.companyName) !=
              undefined && x.year == this.Years[2]
        );

        this.GroupChartYear1.forEach((chart) => {
          chart.positions = chart.positions.filter(
            (x) => x.position == this.BODPosition
          );
        });
        this.GroupChartYear2.forEach((chart) => {
          chart.positions = chart.positions.filter(
            (x) => x.position == this.BODPosition
          );
        });
        this.GroupChartYear3.forEach((chart) => {
          chart.positions = chart.positions.filter(
            (x) => x.position == this.BODPosition
          );
        });

        this.FeesGroupChartYear1.forEach((chart) => {
          chart.positions = chart.positions.filter(
            (x) => x.position == this.BODPosition
          );
        });
        this.FeesGroupChartYear2.forEach((chart) => {
          chart.positions = chart.positions.filter(
            (x) => x.position == this.BODPosition
          );
        });
        this.FeesGroupChartYear3.forEach((chart) => {
          chart.positions = chart.positions.filter(
            (x) => x.position == this.BODPosition
          );
        });
      }
    },
    GenerateImageYear1: function () {
      var node = document.getElementById("DownloadChart1");
      var note = document.getElementById("NoteYear1");

      const SelectedFilters = document.getElementById("SelectedFilters");
      const clone = SelectedFilters.cloneNode(true);

      clone.style.margin = "0";
      clone.style.paddingTop = "25px";
      clone.style.backgroundColor = "#ffffff";
      clone.style.width = "1164px";

      var ClearAll = clone.getElementsByClassName("ClearAll");

      var Prop = ClearAll[0].cloneNode(true);

      ClearAll[0].remove();

      Prop.innerText =
        "DATA AS OF PERFORMANCE YEAR " +
        this.Years[0] +
        " | Position - " +
        this.BODPosition;

      Prop.style.display = "inline-block";
      Prop.style.paddingTop = "12px";
      Prop.style.paddingBottom = "12px";
      Prop.style.paddingLeft = "12px";
      Prop.style.width = "1152px";
      Prop.style.color = "#bca15a";
      Prop.style.backgroundColor = "#f4f4f4";

      var Cross = clone.getElementsByClassName("FilterCross");

      for (let index = 0; index < Cross.length; index++) {
        const element = Cross[index];
        element.style.width = "0px";
        element.style.margin = "0";
      }

      document.getElementById("DownloadFiltersNote1").appendChild(Prop);
      document.getElementById("DownloadFiltersView1").appendChild(clone);

      node.style.paddingTop = "34px";
      node.style.paddingLeft = "24px";
      node.style.width = "1190px";
      note.style = null;

      var FileName = "Board_of_Directors_compensation";

      if (
        this.SelectMainCompany != undefined &&
        this.SelectMainCompany.name != undefined
      ) {
        FileName += "__" + this.SelectMainCompany.name;
      }

      FileName += "__" + this.Years[0].toString();
      FileName += "__" + this.BODPosition.replaceAll(" ", "_") + ".jpeg";

      htmlToImage.toJpeg(node, { quality: 1 }).then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = FileName;
        link.href = dataUrl;
        link.click();
      });

      setTimeout(() => {
        node.style = null;
        note.style = null;
        clone.remove();
        Prop.remove();
      }, 50);
    },
    GenerateImageYear2: function () {
      var node = document.getElementById("DownloadChart2");
      var note = document.getElementById("NoteYear2");

      const SelectedFilters = document.getElementById("SelectedFilters");
      const clone = SelectedFilters.cloneNode(true);

      clone.style.margin = "0";
      clone.style.paddingTop = "25px";
      clone.style.backgroundColor = "#ffffff";
      clone.style.width = "1164px";

      var ClearAll = clone.getElementsByClassName("ClearAll");

      var Prop = ClearAll[0].cloneNode(true);

      ClearAll[0].remove();

      Prop.innerText =
        "DATA AS OF PERFORMANCE YEAR " +
        this.Years[1] +
        " | Position - " +
        this.BODPosition;

      Prop.style.display = "inline-block";
      Prop.style.paddingTop = "12px";
      Prop.style.paddingBottom = "12px";
      Prop.style.paddingLeft = "12px";
      Prop.style.width = "1152px";
      Prop.style.color = "#bca15a";
      Prop.style.backgroundColor = "#f4f4f4";

      var Cross = clone.getElementsByClassName("FilterCross");

      for (let index = 0; index < Cross.length; index++) {
        const element = Cross[index];
        element.style.width = "0px";
        element.style.margin = "0";
      }

      document.getElementById("DownloadFiltersNote2").appendChild(Prop);
      document.getElementById("DownloadFiltersView2").appendChild(clone);

      node.style.paddingTop = "34px";
      node.style.paddingLeft = "24px";
      node.style.width = "1190px";
      note.style = null;

      var FileName = "Board_of_Directors_compensation";

      if (
        this.SelectMainCompany != undefined &&
        this.SelectMainCompany.name != undefined
      ) {
        FileName += "__" + this.SelectMainCompany.name;
      }

      FileName += "__" + this.Years[1].toString();
      FileName += "__" + this.BODPosition.replaceAll(" ", "_") + ".jpeg";

      htmlToImage.toJpeg(node, { quality: 1 }).then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = FileName;
        link.href = dataUrl;
        link.click();
      });

      setTimeout(() => {
        node.style = null;
        note.style = null;
        clone.remove();
        Prop.remove();
      }, 50);
    },
    GenerateImageYear3: function () {
      var node = document.getElementById("DownloadChart3");
      var note = document.getElementById("NoteYear3");

      const SelectedFilters = document.getElementById("SelectedFilters");
      const clone = SelectedFilters.cloneNode(true);

      clone.style.margin = "0";
      clone.style.paddingTop = "25px";
      clone.style.backgroundColor = "#ffffff";
      clone.style.width = "1164px";

      var ClearAll = clone.getElementsByClassName("ClearAll");

      var Prop = ClearAll[0].cloneNode(true);

      ClearAll[0].remove();

      Prop.innerText =
        "DATA AS OF PERFORMANCE YEAR " +
        this.Years[2] +
        " | Position - " +
        this.BODPosition;

      Prop.style.display = "inline-block";
      Prop.style.paddingTop = "12px";
      Prop.style.paddingBottom = "12px";
      Prop.style.paddingLeft = "12px";
      Prop.style.width = "1152px";
      Prop.style.color = "#bca15a";
      Prop.style.backgroundColor = "#f4f4f4";

      var Cross = clone.getElementsByClassName("FilterCross");

      for (let index = 0; index < Cross.length; index++) {
        const element = Cross[index];
        element.style.width = "0px";
        element.style.margin = "0";
      }

      document.getElementById("DownloadFiltersNote3").appendChild(Prop);
      document.getElementById("DownloadFiltersView3").appendChild(clone);

      node.style.paddingTop = "34px";
      node.style.paddingLeft = "24px";
      node.style.width = "1190px";
      note.style = null;

      var FileName = "Board_of_Directors_compensation";

      if (
        this.SelectMainCompany != undefined &&
        this.SelectMainCompany.name != undefined
      ) {
        FileName += "__" + this.SelectMainCompany.name;
      }

      FileName += "__" + this.Years[2].toString();
      FileName += "__" + this.BODPosition.replaceAll(" ", "_") + ".jpeg";

      htmlToImage.toJpeg(node, { quality: 1 }).then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = FileName;
        link.href = dataUrl;
        link.click();
      });

      setTimeout(() => {
        node.style = null;
        note.style = null;
        clone.remove();
        Prop.remove();
      }, 50);
    },
  },
  watch: {
    SelectMainCompany: {
      handler: function () {
        this.BOD = JSON.parse(localStorage.getItem("bod") || "[]");
        if (
          this.BOD != undefined &&
          this.BOD.companyData != undefined &&
          this.BOD.companyData.length > 0
        ) {
          this.UpdateCompanyChart();
          this.UpdateGroupChart();
        }
      },
      deep: true,
    },
    SelectedCompanyList: {
      handler: function () {
        this.BOD = JSON.parse(localStorage.getItem("bod") || "[]");
        if (
          this.BOD != undefined &&
          this.BOD.companyData != undefined &&
          this.BOD.companyData.length > 0
        ) {
          this.UpdateCompanyChart();
          this.UpdateGroupChart();
        }
      },
      deep: true,
    },
    BODPosition: function () {
      this.BOD = JSON.parse(localStorage.getItem("bod") || "[]");
      if (
        this.BOD != undefined &&
        this.BOD.companyData != undefined &&
        this.BOD.companyData.length > 0
      ) {
        this.UpdateCompanyChart();
        this.UpdateGroupChart();
      }
    },
  },
};
</script>

<style scoped>
.BODCompensation {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;

  margin-top: 25px;
  margin-left: 24px;
}

.Charts {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.Chart {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  background-color: #ffffff;
}

.ChartTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 16px;
  font-weight: 600;
  color: #8a8a8ab3;

  padding-left: 12px;

  height: 35px;

  background-color: #f4f4f4;

  max-width: 1152px;
  width: 100%;
}

.ChartTitleText {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.ChartYear {
  margin-left: 10px;

  color: #000000;
}

.GenerateImage {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.GenerateImage span {
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-size: 14px;
  font-weight: 500;

  width: 220px;
  height: 34px;

  color: #ffffff;

  background-color: #bca15a;

  cursor: pointer;

  transition: 0.3s;
}

.GenerateImage span:hover {
  opacity: 0.7;

  transition: 0.3s;
}

.GenerateImage img {
  height: 24px;

  margin-left: 10px;
}

.DownloadChart {
  background-color: #ffffff;
}

.Chart {
  padding-top: 30px;
}

.Note {
  max-width: 1144px;
  box-shadow: 4px 4px 20px rgb(0 0 0 / 9%);
  padding: 10px;

  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #8a8a8ab3;

  margin-top: -40px;
  margin-bottom: 34px;
}
</style>
