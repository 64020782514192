<template>
  <div class="NetworkGraphView">
    <span class="Note"
      >Please click on a company (node), a connection line between 2 companies,
      or use search</span
    >
    <div class="Searching">
      <div class="SearchBlock">
        <span>Company</span>
        <v-select
          class="DropDownList"
          style="height: 30px"
          v-model="SearchingCompany"
          :options="CompanyList"
          :reduce="(Company) => Company.id"
          label="name"
        />
      </div>
      <div class="SearchBlock">
        <span>Member</span>
        <v-select
          class="DropDownList"
          style="height: 30px"
          v-model="SearchingMember"
          :options="PersonList"
          :reduce="(Person) => Person.id"
          label="name"
        />
      </div>
    </div>
    <div class="Graph" ref="Graph">
      <v-network-graph
        ref="ngraph"
        class="ngraph"
        :nodes="nodes"
        :edges="edges"
        :configs="configs"
        v-model:selected-nodes="selectedNodes"
        v-model:selected-edges="selectedEdges"
        v-model:zoom-level="ZoomGraph"
      >
        <defs>
          <clipPath id="iconCircle" clipPathUnits="objectBoundingBox">
            <circle cx="0.5" cy="0.5" r="0.5" />
          </clipPath>
        </defs>
        <!-- Replace the node component -->
        <template #override-node="{ nodeId, scale, config, ...slotProps }">
          <circle
            class="icon-circle"
            :r="config.radius * scale"
            :fill="config.color"
            v-bind="slotProps"
          />
          <!-- Use v-html to interpret escape sequences for icon characters. -->
          <image
            class="icon-picture"
            :x="-config.radius * scale * 0.7"
            :y="-config.radius * scale * 0.7"
            :width="config.radius * scale * 2 * 0.7"
            :height="config.radius * scale * 2 * 0.7"
            :xlink:href="`./NodeIcons/${nodes[nodeId].icon}`"
            clip-path="url(#iconCircle)"
          />
        </template>
      </v-network-graph>
    </div>
  </div>
  <div v-if="SelectCompanyCheck" class="ModalView">
    <div class="SelectCompany">
      <img
        class="Image"
        :src="require('@/assets/NetworkGraph/NodeIcons/' + SelectCompany.icon)"
      />
      <span class="Name">{{ SelectCompany.name }}</span>
      <div class="Tables">
        <div
          class="Table"
          v-if="
            SelectCompany.listMember != undefined &&
            SelectCompany.listMember.bodMembers != undefined &&
            SelectCompany.listMember.bodMembers.length > 0
          "
        >
          <span class="Header">BOD members:</span>
          <span
            class="Member"
            @click="ChangeMember(Member)"
            v-for="Member of SelectCompany.listMember.bodMembers"
            :key="Member.id"
            v-bind:Member="Member"
            >{{ Member.name }}, {{ Member.position }}</span
          >
        </div>
        <div
          class="Table"
          v-if="
            SelectCompany.listMember != undefined &&
            SelectCompany.listMember.exbMembers != undefined &&
            SelectCompany.listMember.exbMembers.length > 0
          "
        >
          <span class="Header">EXB members:</span>
          <span
            class="Member"
            @click="ChangeMember(Member)"
            v-for="Member of SelectCompany.listMember.exbMembers"
            :key="Member.id"
            v-bind:Member="Member"
            >{{ Member.name }}, {{ Member.position }}</span
          >
        </div>
      </div>
      <img
        src="@/assets/NetworkGraph/CrossIcon.svg"
        @click="SelectCompanyCheck = false"
        class="ExitDialog"
      />
    </div>
  </div>
  <div v-if="SelectMemberCheck" class="ModalView">
    <div class="SelectCompany">
      <img
        class="Image"
        :src="require('@/assets/NetworkGraph/PersonIcon.svg')"
      />
      <span class="Name">{{ SelectMember.name }}</span>
      <div class="PartCompanies">
        <div
          class="Company"
          v-for="Company of SelectMember.companiesList"
          :key="Company.companyId"
          v-bind:Company="Company"
        >
          <img
            class="Image"
            :src="require('@/assets/NetworkGraph/NodeIcons/' + Company.icon)"
          />
          <span class="CompanyName" @click="ChangeMemberCompany(Company)">{{
            Company.companyName
          }}</span>
          <span
            v-for="Position of Company.positionMember"
            :key="Position"
            v-bind:Position="Position"
            class="PositionMember"
            >{{ Position }}</span
          >
        </div>
      </div>
      <img
        src="@/assets/NetworkGraph/CrossIcon.svg"
        @click="SelectMemberCheck = false"
        class="ExitDialog"
      />
    </div>
  </div>
  <div v-if="AllCheck" class="ModalView">
    <div class="SelectAll">
      <div class="SelectCompany AllSelect">
        <img
          class="Image"
          :src="
            require('@/assets/NetworkGraph/NodeIcons/' + SelectCompany.icon)
          "
        />
        <span class="Name">{{ SelectCompany.name }}</span>
        <div class="Tables">
          <div
            class="Table"
            v-if="
              SelectCompany.listMember != undefined &&
              SelectCompany.listMember.bodMembers != undefined &&
              SelectCompany.listMember.bodMembers.length > 0
            "
          >
            <span class="Header">BOD members:</span>
            <span
              class="Member"
              @click="ChangeMember(Member)"
              v-for="Member of SelectCompany.listMember.bodMembers"
              :key="Member.id"
              v-bind:Member="Member"
              >{{ Member.name }}, {{ Member.position }}</span
            >
          </div>
          <div
            class="Table"
            v-if="
              SelectCompany.listMember != undefined &&
              SelectCompany.listMember.exbMembers != undefined &&
              SelectCompany.listMember.exbMembers.length > 0
            "
          >
            <span class="Header">EXB members:</span>
            <span
              class="Member"
              @click="ChangeMember(Member)"
              v-for="Member of SelectCompany.listMember.exbMembers"
              :key="Member.id"
              v-bind:Member="Member"
              >{{ Member.name }}, {{ Member.position }}</span
            >
          </div>
        </div>
        <img
          src="@/assets/NetworkGraph/CrossIcon.svg"
          @click="AllCheck = false"
          class="ExitDialog"
        />
      </div>

      <div class="SelectCompany AllSelect" v-if="SelectMembers.length == 0">
        <img
          class="Image"
          :src="require('@/assets/NetworkGraph/PersonIcon.svg')"
        />
        <span class="Name">{{ SelectMember.name }}</span>
        <div class="PartCompanies">
          <div
            class="Company"
            v-for="Company of SelectMember.companiesList"
            :key="Company.companyId"
            v-bind:Company="Company"
          >
            <img
              class="Image"
              :src="require('@/assets/NetworkGraph/NodeIcons/' + Company.icon)"
            />
            <span class="CompanyName" @click="ChangeMemberCompany(Company)">{{
              Company.companyName
            }}</span>
            <span
              v-for="Position of Company.positionMember"
              :key="Position"
              v-bind:Position="Position"
              class="PositionMember"
              >{{ Position }}</span
            >
          </div>
        </div>

        <img
          src="@/assets/NetworkGraph/CrossIcon.svg"
          @click="AllCheck = false"
          class="ExitDialog"
        />
      </div>
      <div
        class="SelectCompany SelectMembers AllSelect"
        v-if="SelectMembers.length > 0"
      >
        <div
          class="SelectMember"
          v-for="Member of SelectMembers"
          :key="Member.memberId"
          v-bind:Member="Member"
        >
          <img
            class="Image"
            :src="require('@/assets/NetworkGraph/PersonIcon.svg')"
          />
          <span class="Name">{{ Member.name }}</span>
          <div class="PartCompanies">
            <div
              class="Company"
              v-for="Company of Member.companiesList.filter(
                (x) =>
                  SelectMembers.find(
                    (y) =>
                      y.id != Member.id &&
                      y.companiesList.find((z) => z.companyId == x.companyId) !=
                        undefined
                  ) != undefined
              )"
              :key="Company.companyId"
              v-bind:Company="Company"
            >
              <img
                class="Image"
                :src="require('@/assets/NetworkGraph/CompanyIcon.svg')"
              />
              <span class="CompanyName" @click="ChangeMemberCompany(Company)">{{
                Company.companyName
              }}</span>
              <span
                v-for="Position of Company.positionMember"
                :key="Position"
                v-bind:Position="Position"
                class="PositionMember"
                >{{ Position }}</span
              >
            </div>
            <div
              class="Company"
              v-for="Company of Member.companiesList.filter(
                (x) =>
                  SelectMembers.find(
                    (y) =>
                      y.id != Member.id &&
                      y.companiesList.find((z) => z.companyId == x.companyId) ==
                        undefined
                  ) != undefined
              )"
              :key="Company.companyId"
              v-bind:Company="Company"
            >
              <img
                class="Image"
                :src="require('@/assets/NetworkGraph/CompanyIcon.svg')"
              />
              <span class="CompanyName" @click="ChangeMemberCompany(Company)">{{
                Company.companyName
              }}</span>
              <span
                v-for="Position of Company.positionMember"
                :key="Position"
                v-bind:Position="Position"
                class="PositionMember"
                >{{ Position }}</span
              >
            </div>
          </div>
        </div>
        <img
          src="@/assets/NetworkGraph/CrossIcon.svg"
          @click="AllCheck = false"
          class="ExitDialog"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { reactive } from "vue";
import * as vNG from "v-network-graph";
import { ForceLayout } from "v-network-graph/lib/force-layout";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      NetworkData: [],
      nodes: [],
      edges: [],
      layouts: { nodes: [] },
      ZoomGraph: 0.3,
      TypeGraph: "Circle",

      selectedNodes: [],
      selectedEdges: [],

      configs: reactive(
        vNG.defineConfigs({
          view: {
            scalingObjects: true,
            layoutHandler: new ForceLayout({
              positionFixedByDrag: false,
              positionFixedByClickWithAltKey: true,
              createSimulation: (d3, nodes, edges) => {
                const forceLink = d3.forceLink(edges);
                return d3
                  .forceSimulation(nodes)
                  .force("edge", forceLink.distance(600))
                  .force("charge", d3.forceManyBody())
                  .force("collide", d3.forceCollide(60).strength(0.2))
                  .force("center", d3.forceCenter().strength(0.1))
                  .alphaMin(0.001);
              },
            }),
          },
          node: {
            selectable: true,
            normal: { radius: (node) => node.size, color: "rgb(56, 127, 179)" },
            hover: {
              color: "#a89f64",
            },
            label: { color: "#000000", margin: 4 },
          },
          edge: {
            selectable: true,
            normal: {
              color: "#c0c2c4",
              width: 1,
            },
            hover: {
              color: "rgb(230, 189, 64)",
            },
            selected: {
              color: "rgb(230, 189, 64)",
              dasharray: "0",
              linecap: "square",
            },
            margin: 4,
            zOrder: {
              enabled: true,
              bringToFrontOnHover: true,
              bringToFrontOnSelected: true,
            },
          },
        })
      ),

      SelectCompanyCheck: false,
      SelectMemberCheck: false,
      AllCheck: false,

      SelectCompany: {},
      SelectMember: {},
      SelectMembers: [],

      CompanyList: [],
      PersonList: [],

      SearchedCompany: {},
      SearchedMember: {},

      SearchingCompany: {},
      SearchingMember: {},

      EdgeLayoutMax: [],
      EdgeLayoutMiddle: [],
      EdgeLayoutMin: [],

      SearchingMemberCheck: false,
    };
  },
  mounted() {
    axios
      .get(this.$api_url + "NetPerson/GetCompanies")
      .then((response) => {
        this.CompanyList = response.data;
      })
      .catch((error) => console.log(error));

    axios
      .get(this.$api_url + "NetPerson/GetPersonList")
      .then((response) => {
        this.PersonList = response.data;
      })
      .catch((error) => console.log(error));

    var Data = JSON.parse(localStorage.getItem("networkData"));

    this.NetworkData = Data.data;
    this.nodes = Data.nodes;
    this.edges = Data.edges;

    console.clear();
  },
  methods: {
    ClickNode: function (event, node) {
      this.ChangeCompany(node);
    },
    ClickLink: function (event, link) {
      var FromCompany = this.NetworkData.find(
        (x) => x.companyId == link.source
      ).members;
      var ToCompany = this.NetworkData.find(
        (x) => x.companyId == link.target
      ).members;

      var Members = [];

      FromCompany.forEach((member) => {
        if (
          ToCompany.find((x) => x.memberId == member.memberId) != undefined &&
          ToCompany.find((x) => x.memberId == member.memberId) != undefined
        ) {
          Members.push(member);
        }
      });

      if (Members.length == 1) {
        this.ChangePerson(link);
      }
    },
    ChangeZoom: function () {
      let Graph = document.getElementById("NetworkGraph");
      Graph.style.transform = "scale(" + this.ZoomGraph / 100 + ")";
    },
    ChangeCompany: function (node) {
      this.SelectCompany.id = node.id;
      this.SelectCompany.name = node.name;
      this.SelectCompany.icon = node.icon;

      axios
        .get(this.$api_url + "NetPerson/GetMemberCompany?Id=" + node.id)
        .then((response) => {
          this.SelectCompany.listMember = response.data;

          if (this.SelectMemberCheck || this.AllCheck) {
            this.SelectMemberCheck = false;
            this.AllCheck = false;
            this.AllCheck = true;
          } else {
            this.SelectCompanyCheck = true;
          }
        })
        .catch((error) => console.log(error));
    },
    ChangeMemberCompany: function (Company) {
      this.SelectCompany.id = Company.companyId;
      this.SelectCompany.name = Company.companyName;
      this.SelectCompany.icon = this.nodes.find(
        (x) => x.id == Company.companyId
      ).icon;

      axios
        .get(
          this.$api_url + "NetPerson/GetMemberCompany?Id=" + Company.companyId
        )
        .then((response) => {
          this.SelectCompany.listMember = response.data;

          if (this.SelectMemberCheck || this.AllCheck) {
            this.SelectMemberCheck = false;
            this.AllCheck = false;
            this.AllCheck = true;
          } else {
            this.SelectCompanyCheck = true;
          }
        })
        .catch((error) => console.log(error));
    },
    ChangePerson: function (link) {
      this.SelectMembers = [];

      this.SelectMember.id = link.memberId;
      this.SelectMember.name = link.memberName;

      axios
        .get(this.$api_url + "NetPerson/GetCompany?Id=" + link.memberId)
        .then((response) => {
          this.SelectMember.companiesList = response.data;

          this.SelectMember.companiesList.forEach((company) => {
            company.icon = this.nodes.find(
              (x) => x.id == company.companyId
            ).icon;
          });

          if (this.SelectCompanyCheck || this.AllCheck) {
            this.SelectCompanyCheck = false;
            this.AllCheck = false;
            this.AllCheck = true;
          } else {
            this.SelectMemberCheck = true;
          }
        })
        .catch((error) => console.log(error));
    },
    ChangeMember: function (Member) {
      this.SelectMembers = [];

      this.SelectMember.id = Member.personaId;
      this.SelectMember.name = Member.name;

      axios
        .get(this.$api_url + "NetPerson/GetCompany?Id=" + Member.personaId)
        .then((response) => {
          this.SelectMember.companiesList = response.data;

          this.SelectMember.companiesList.forEach((company) => {
            company.icon = this.nodes.find(
              (x) => x.id == company.companyId
            ).icon;
          });

          if (this.SelectCompanyCheck || this.AllCheck) {
            this.SelectCompanyCheck = false;
            this.AllCheck = false;
            this.AllCheck = true;
          } else {
            this.SelectMemberCheck = true;
          }
        })
        .catch((error) => console.log(error));
    },
    SearchCompany: function (id) {
      if (id != null) {
        this.SearchedCompany.id = id;
        this.SearchedCompany.shortName = this.CompanyList.find(
          (x) => x.id == id
        ).name;
        this.SelectCompany.icon = this.nodes.find(
          (x) => x.id == this.SearchedCompany.id
        ).icon;

        this.SearchedMember.id = null;
        this.SearchedMember.name = null;

        this.SearchingMember = null;

        this.selectedNodes = [];
        this.selectedEdges = [];

        var node = this.nodes.findIndex((x) => x.id == this.SearchedCompany.id);

        if (node != undefined && node != null) {
          this.selectedNodes.push(
            this.nodes.findIndex((x) => x.id == this.SearchedCompany.id)
          );

          var coordinates = document.getElementsByClassName("v-node");

          var nodeCoordinate = coordinates[node];

          this.ZoomGraph = 0.8;

          this.$nextTick(() => {
            const sizes = this.$refs.ngraph.getSizes();

            this.$refs.ngraph.panTo({
              x: sizes.width / 2 - nodeCoordinate.transform.animVal[0].matrix.e,
              y:
                sizes.height / 2 - nodeCoordinate.transform.animVal[0].matrix.f,
            });
          });
          /*
        var tempEdges = this.edges.filter(
          (x) => x.source == node || x.target == node
        );

        tempEdges.forEach((tempEdge) => {
          this.selectedEdges.push(
            this.edges.findIndex(
              (x) => x.source == tempEdge.source && x.target == tempEdge.target
            )
          );
        });
        */
        }
      } else {
        this.SearchedCompany.id = null;
        this.SearchedCompany.shortName = null;
      }
    },
    SearchMember: function (id) {
      if (id != null) {
        this.SearchedMember.id = id;
        this.SearchedMember.name = this.PersonList.find(
          (x) => x.id == this.SearchedMember.id
        ).name;

        this.SearchedCompany.id = null;
        this.SearchedCompany.shortName = null;

        this.SearchingCompany = null;

        this.selectedNodes = [];
        this.selectedEdges = [];

        if (this.SearchedMember.id != null) {
          var tempEdges = this.edges.filter(
            (x) => x.memberId == this.SearchedMember.id
          );

          tempEdges.forEach((tempEdge) => {
            this.selectedNodes.push(tempEdge.source);
            this.selectedNodes.push(tempEdge.target);
          });

          this.SearchingMemberCheck = true;

          if (tempEdges.length > 0) {
            var coordinates = document.getElementsByClassName("v-node");

            var nodeCoordinate1 = coordinates[tempEdges[0].source];
            var nodeCoordinate2 = coordinates[tempEdges[0].target];

            this.ZoomGraph = 0.5;

            this.$nextTick(() => {
              const sizes = this.$refs.ngraph.getSizes();

              var x =
                (sizes.width / 2 -
                  nodeCoordinate1.transform.animVal[0].matrix.e +
                  sizes.width / 2 -
                  nodeCoordinate2.transform.animVal[0].matrix.e) /
                2;

              var y =
                (sizes.height / 2 -
                  nodeCoordinate1.transform.animVal[0].matrix.f +
                  sizes.height / 2 -
                  nodeCoordinate2.transform.animVal[0].matrix.f) /
                2;

              this.$refs.ngraph.panTo({
                x: x,
                y: y,
              });
            });
          } else {
            var link = {
              memberId: this.SearchedMember.id,
              memberName: this.SearchedMember.name,
            };
            this.ChangePerson(link);
          }
        }
      } else {
        this.SearchedMember.id = null;
        this.SearchedMember.name = null;
      }
    },
  },
  watch: {
    selectedNodes: function () {
      if (this.selectedNodes.length > 0 && !this.SearchingMemberCheck)
        this.ChangeCompany(this.nodes[this.selectedNodes[0]]);
    },
    selectedEdges: function () {
      if (this.selectedEdges.length > 0)
        this.ChangePerson(this.edges[this.selectedEdges[0]]);
    },
    SearchingCompany: function () {
      this.SearchCompany(this.SearchingCompany);
    },
    SearchingMember: function () {
      this.SearchMember(this.SearchingMember);
    },
    SearchingMemberCheck: function () {
      axios
        .get(
          this.$api_url + "NetPerson/GetCompany?Id=" + this.SearchedMember.id
        )
        .then((response) => {
          this.SelectMember.companiesList = response.data;

          this.SelectMember.companiesList.forEach((company) => {
            company.icon = this.nodes.find(
              (x) => x.id == company.companyId
            ).icon;
          });

          if (this.SelectCompanyCheck || this.AllCheck) {
            this.SelectCompanyCheck = false;
            this.AllCheck = false;
            this.AllCheck = true;
          } else {
            this.SelectMemberCheck = true;
          }
        })
        .catch((error) => console.log(error));

      this.SearchingMemberCheck = false;
    },
  },
};
</script>

<style scoped>
.NetworkGraphView {
  display: block;
  position: relative;

  width: calc(100%);
  height: calc(100% - 170px);
}

.Note {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  font-size: 16px;
  font-weight: 500;
  color: #8a8a8ab3;

  height: 24px;

  padding: 10px 25px;

  background-color: rgba(196, 196, 196, 0.4);
}

.NetworkGraphView .Searching {
  display: flex;
  flex-flow: row;

  margin: 15px 30px;
}

.NetworkGraphView .Searching .SearchBlock {
  display: flex;
  flex-flow: row;
  align-items: center;

  margin-top: 15px;
  margin-right: 30px;
}

.NetworkGraphView .Searching .SearchBlock span {
  height: 22px;
  font-size: 18px;
  font-weight: 500;
  color: #8a8a8ab3;

  margin-right: 15px;
}

.NetworkGraphView .Searching .SearchBlock .DropDownList {
  width: 400px;
  height: 24px;

  font-size: 14px;
  line-height: 14px !important;
  color: #8a8a8ab3;
}

.NetworkGraphView .Searching .SearchBlock .vs__dropdown-toggle {
  height: 27px;
}

.Graph {
  display: block;

  width: 100%;
  height: calc(100% - 55px);

  overflow: scroll;
  scroll-behavior: smooth;
}

.ngraph {
  width: 100%;
  height: 98%;
}

.ModalView {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.5);

  z-index: 99;
}

.SelectAll {
  display: flex;
  flex-direction: row;
}

.AllSelect:first-child {
  margin-right: 50px;
}

.SelectCompany {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;

  position: relative;
  width: 600px;
  height: 550px;
  background-color: white;
  padding-top: 20px;

  overflow-y: scroll;

  overflow-x: hidden;
}

.SelectMembers {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  position: relative;
  width: 600px;
  height: 550px;
  background-color: white;
  padding-top: 20px;

  overflow-y: scroll;

  overflow-x: scroll;
}

.SelectMembers .SelectMember {
  width: 195px !important;
}

.SelectMembers .PartCompanies {
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  flex-wrap: nowrap !important;
  transform: scale(1) !important;
  width: 195px !important;
  margin-top: 10px;
}

.SelectMembers .Company {
  width: 185px !important;
  transform: scale(0.8) !important;
}

.SelectMember {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;

  position: relative;
  width: 600px;

  background-color: white;
}

.SelectCompany .Image {
  width: 80px;
  height: 80px;
}

.SelectCompany .Name {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.SelectCompany .Tables {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.SelectCompany .Table {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  width: 220px;
}

.SelectCompany .Table:nth-child(2n) {
  align-items: flex-end;
}

.SelectCompany .Header {
  display: block;
  margin-bottom: 15px;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.SelectCompany .Member {
  display: block;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;

  cursor: pointer;

  color: #789ecf;
}

.SelectCompany .PartCompanies {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  transform: scale(0.8);

  width: 600px;

  margin-top: 10px;
}

.SelectCompany .Company {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 250px;
  height: 220px;

  color: #789ecf;
}

.SelectCompany .CompanyName {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;

  margin-top: 10px;
  margin-bottom: 10px;

  cursor: pointer;
}

.SelectCompany .PositionMember {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;

  width: 80%;

  color: #000000;
}

.ExitDialog {
  position: absolute;

  top: 6px;
  right: 16px;

  width: 24px;
  height: 24px;

  padding: 6px;

  border-radius: 50%;

  transition: 0.5s;
}

.ExitDialog:hover {
  transition: 0.5s;
  transform: rotate(180deg);
  background: #f2f6f9;
}

.icon-circle,
.icon-picture {
  transition: all 0.1s linear;
}

.icon-picture {
  pointer-events: none;
}
</style>

<style>
.NetworkNode:hover {
  fill: #789ecf !important;
}

.link:hover {
  stroke: #789ecf !important;
  stroke-width: 4px !important;
  stroke-linecap: butt !important;
}

.Searching .vs--single .vs__selected {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 14px;
  font-weight: 500;
  color: #8a8a8ab3;
}

.Searching .vs__selected-options {
  align-items: center;
}

.Searching .vs__dropdown-option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  z-index: 10;
}
</style>
