<template>
  <div class="HCMWeb">
    <div class="Loading" id="Loading">
      <div class="LoadingCircle">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <NavBar
      v-bind:User="User"
      v-if="$route.name != 'AuthenticationPage'"
      ref="NavBar"
    />
    <router-view v-slot="{ Component }">
      <component
        v-on:UpdateData="UpdateData"
        v-on:CheckSession="CheckSession"
        :is="Component"
      ></component>
    </router-view>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "@/components/NavBar/NavBar.vue";

export default {
  name: "App",
  components: {
    NavBar,
  },
  data() {
    return {
      interval: null,
    };
  },
  created() {
    document.title = "GetCompIA";

    this.interval = setInterval(() => this.CheckSession(), 300000);

    setTimeout(() => {
      this.CheckSession();
    }, 1000);

    this.UpdateData();
  },
  beforeUnmount() {
    clearInterval();
  },
  methods: {
    CheckSession() {
      if (
        localStorage.userId != undefined &&
        localStorage.session != undefined
      ) {
        var SessionView = {
          SessionId: localStorage.session,
          UserId: localStorage.userId,
          MacInfo: navigator.userAgent,
        };

        axios
          .put(this.$api_url + "HCMWeb/CheckSession", SessionView)
          .then((response) => {
            if (response.data == false) {
              localStorage.clear();
              window.location.href = "https://www.getcompia.com/";
            }

            axios
              .get(
                this.$api_url + "HCMWeb/CheckUser?UserId=" + localStorage.userId
              )
              .then((response) => {
                localStorage.currentUser = JSON.stringify(response.data);

                this.$refs.NavBar.CheckUser();
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
            localStorage.clear();
            window.location.href = "https://www.getcompia.com/";
          });
      }
    },
    UpdateData: async function () {
      if (
        document.getElementById("Loading") != undefined &&
        (localStorage.exb == undefined ||
          localStorage.bod == undefined ||
          localStorage.networkData == undefined)
      )
        document.getElementById("Loading").style.display = "flex";

      axios
        .get(this.$api_url + "HCMWeb/GetSOP")
        .then((response) => {
          localStorage.sop = JSON.stringify(response.data);
        })
        .catch((error) => console.log(error));

      axios
        .get(this.$api_url + "HCMWeb/GetEXB")
        .then((response) => {
          localStorage.exb = JSON.stringify(response.data);

          document.getElementById("Loading").style.display = "none";
        })
        .catch((error) => console.log(error));

      axios
        .get(this.$api_url + "HCMWeb/GetBOD")
        .then((response) => {
          localStorage.bod = JSON.stringify(response.data);

          document.getElementById("Loading").style.display = "none";
        })
        .catch((error) => console.log(error));

      axios
        .get(this.$api_url + "NetPerson/GetNetworkGraph")
        .then((response) => {
          var NetworkData = response.data;
          var nodes = [];
          var edges = [];

          NetworkData.forEach((Company) => {
            var icon = "";
            var size = 20;

            switch (Company.industry) {
              case "Health Care":
                icon = "HealthCareIcon.svg";
                break;
              case "Financials":
                icon = "FinancialsIcon.svg";
                break;
              case "Industrials":
                icon = "InductryIcon.svg";
                break;
              case "Consumer Staples":
                icon = "ConsumerStaplesIcon.svg";
                break;
              case "Basic Materials":
                icon = "MaterialIcon.svg";
                break;
              case "Real Estate":
                icon = "RealEstateIcon.svg";
                break;
              case "Technology":
                icon = "InformationTechnologyIcon.svg";
                break;
              case "Consumer Discretionary":
                icon = "ConsumerIcon.svg";
                break;
              case "Utilities":
                icon = "UtilitiesIcon.svg";
                break;
              case "Telecommunications":
                icon = "ComServiceIcon.svg";
                break;
              default:
                icon = "DefaultIcon.svg";
                break;
            }

            switch (Company.index) {
              case "SPI Small":
                size = 10;
                break;
              case "SPI Mid":
                size = 20;
                break;
              case "SPI Large":
                size = 30;
                break;

              default:
                break;
            }

            nodes.push({
              id: Company.companyId,
              name: Company.companyName,
              icon: icon,
              size: size,
            });
          });

          NetworkData.forEach((Company) => {
            Company.members.forEach((Member) => {
              NetworkData.forEach((FindCompany) => {
                if (FindCompany.companyId != Company.companyId) {
                  if (
                    FindCompany.members.find(
                      (x) => x.memberId == Member.memberId
                    ) != undefined &&
                    FindCompany.members.find(
                      (x) => x.memberId == Member.memberId
                    ) != null
                  ) {
                    if (
                      edges.find(
                        (x) =>
                          x.source ==
                            nodes.findIndex(
                              (x) => x.id == FindCompany.companyId
                            ) &&
                          x.target ==
                            nodes.findIndex((x) => x.id == Company.companyId)
                      ) == undefined ||
                      edges.find(
                        (x) =>
                          x.source ==
                            nodes.findIndex(
                              (x) => x.id == FindCompany.companyId
                            ) &&
                          x.target ==
                            nodes.findIndex((x) => x.id == Company.companyId)
                      ) == null
                    )
                      edges.push({
                        source: nodes.findIndex(
                          (x) => x.id == Company.companyId
                        ),
                        target: nodes.findIndex(
                          (x) => x.id == FindCompany.companyId
                        ),
                        memberId: Member.memberId,
                        memberName: Member.memberName,
                      });
                  }
                }
              });
            });
          });

          var result = { nodes: nodes, edges: edges, data: NetworkData };

          localStorage.networkData = JSON.stringify(result);

          document.getElementById("Loading").style.display = "none";
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style>
@import "@/styles_library/LoadingCircle.css";

@font-face {
  font-family: "Effra";
  src: url("@/assets/fonts/Effra.woff2");
}

:root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
}

#app {
  font-family: "Effra";
  width: 100%;
  height: 100%;
}

.HCMWeb {
  width: 100%;
  height: 100%;
}

.v-popper__inner {
  font-family: "Graphik";

  font-size: 14px !important;
  font-weight: 500 !important;
}

.v-popper__inner div {
  max-width: 200px;
  max-height: 30px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vs__dropdown-toggle {
  border-radius: 0;
}

.SelectYear .vs__dropdown-toggle {
  border: none;
}

.SelectYear .vs__dropdown-menu {
  border: none !important;
  width: 140px !important;
  min-width: 140px !important;
}

.SelectYear .vs__clear {
  display: none;
}

.SelectPosition .vs__dropdown-toggle {
  border: none;
}

.SelectPosition .vs__dropdown-menu {
  border: none !important;
  width: 240px !important;
  min-width: 240px !important;
}

.SelectPosition .vs__clear {
  display: none;
}

.CompanyChart .vs__dropdown-toggle {
  border: none;
}

.CompanyChart .vs__dropdown-menu {
  border: none !important;
}

.CompanyChart .vs__clear {
  display: none;
}

.CompanyChart .vs__selected {
  color: #8a8a8ab3;
}

.swal2-container {
  font-family: "Effra" !important;
}

.swal2-styled.swal2-confirm {
  position: relative;

  font-size: 16px;
  font-weight: 700;

  color: #000000;

  background-color: transparent;

  border: 1px solid #bca15a;
  border-left-color: #bca15a !important;
  border-right-color: #bca15a !important;

  border-radius: 0 !important;

  font-family: "Effra" !important;

  z-index: 2;

  transition: 0.1s;
}

.swal2-styled.swal2-confirm::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  content: "";
  background: #bca15a;
  transition: transform 0.3s;

  transform-origin: right top;
  transform: scale(0, 1);
}

.swal2-styled.swal2-confirm:hover {
  color: #fff;

  transition: 0.1s;
}

.swal2-styled.swal2-confirm:hover::before {
  transform-origin: left top;
  transform: scale(1, 1);

  z-index: -1;
}

.swal2-styled:hover {
  color: #fff;
  background-image: none !important;
}
</style>
