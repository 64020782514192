import { createApp } from "vue";
import router from "@/router";
import VueSimpleAlert from "vue3-simple-alert-next";
import VTooltipPlugin from "v-tooltip";
import "v-tooltip/dist/v-tooltip.css";
import "vue-select/dist/vue-select.css";
import App from "./App.vue";
import VNetworkGraph from "v-network-graph";
import "v-network-graph/lib/style.css";

router.beforeEach((to, from, next) => {
  if (
    (localStorage.session == undefined || localStorage.session == null) &&
    to.name !== "AuthenticationPage"
  ) {
    next({ name: "AuthenticationPage" });
  } else {
    if (to.name == "AdminPage" || to.name == "UserEditor") {
      if (localStorage.currentUser != undefined) {
        var User = JSON.parse(localStorage.getItem("currentUser") || "{}");
        String.prototype.replaceAt = function (index, replacement) {
          return (
            this.substring(0, index) +
            replacement +
            this.substring(index + replacement.length)
          );
        };
        var AdminCheck = User.userId.toLowerCase().replace(/[^a-z]/gi, "");

        var num = 1;

        for (let i = 3; i < AdminCheck.length; i++) {
          if (i % 3 == 0) {
            if (num >= User.firstName.length) num = 1;

            AdminCheck = AdminCheck.replaceAt(i, User.firstName[num]);
            num++;
          }
        }

        if (User.admin == AdminCheck) {
          next();
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    next();
  }
});

const app = createApp(App);
app.config.globalProperties.$api_url =
  "https://getcompiaapi.azurewebsites.net/api/";
//app.config.globalProperties.$api_url = "https://localhost:5001/api/";
app.use(VueSimpleAlert);
app.use(VNetworkGraph);
app.use(VTooltipPlugin);
app.use(router);
app.mount("#app");
